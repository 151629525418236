import React from 'react'

const IPPriceTable = ({ count, price }) => {
    return (
        <div className="card-theme">
            <div className="card-body text-center p-0 pb-3">
                <h4 className="pt-2 cards-title">{count} آی پی</h4>
                <div className="mb-3">
                    <span className="pricing-table-price pr-1 pl-1">{price} تومان</span>
                    <p> ماهانه </p>
                </div>
            </div>
        </div>
    )
}

export default IPPriceTable