import React from 'react';

const FlagSVG = () => {
        return (
            <svg viewBox="0 0 745 598.53" className="SVG-flage">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="_2-intro" data-name="2-intro">
                        <path className="cls-1" d="M231.4,11.73A488.67,488.67,0,0,0,109.05,121.19C95.49,138.32-50.67,326.91,18.48,436.73c46.29,73.51,164.11,72.12,268.93,70.89,101.63-1.2,215.11-2.53,254.28-70.89,58-101.23-85.17-282.7-122.81-330.4a704.42,704.42,0,0,0-90.11-94.6,106.89,106.89,0,0,0-97.37,0Z" />
                        <rect className="cls-2" x="190.14" y="397.17" width="75.69" height="199" />
                        <rect className="cls-2" x="287.94" y="397.08" width="75.69" height="199" />
                        <path className="cls-2" d="M323.58,411.53l-97.8.1.87,27.21,39.12.81a12.09,12.09,0,0,1,22.12.83l35.72,1.67Z" />
                        <path className="cls-3" d="M745,185.54a154.45,154.45,0,0,0-55.28-8.5c-20.27.69-33.25,5.37-46.27,8.5-20.84,5-51.57,8.44-93.2,0V67.33c30,7.64,54,8,70.59,6.81,22.81-1.6,33.34-6.14,58.68-8.51A276.49,276.49,0,0,1,745,67.33Z" />
                        <path className="cls-4" d="M391,212.14q.06,6.71.14,13.41c31-10.27,46.93-40.56,39.8-64.77-4.95-16.83-20.77-29.66-40-33.88-.47,3.08-.94,6.17-1.42,9.25,18,2.13,32.2,15.16,35,31.07C428,186.83,413.61,207.91,391,212.14Z" />
                        <rect className="cls-5" x="159.58" y="183.32" width="235.62" height="106.52" rx="12.5" />
                        <path className="cls-2" d="M86,244.86c26.23,28.3,60.81,33.12,71.76,34.14a116.52,116.52,0,0,0,44.14-4.45c-1-20.12-1.6-33.05-1.6-33.06h0A58.63,58.63,0,0,0,191.84,218c-6.06,2.77-19.85,8-36.38,4.7C142,220,133.35,213.05,129,209.51c-32.05-25.86-35.1-83.36-35.4-90.46l-41.94-2.17A196,196,0,0,0,59.42,193C64.83,210.44,70.26,227.93,86,244.86Z" />
                        <path className="cls-2" d="M517.39,268.73a468.68,468.68,0,0,1-93.55,11.06,468.42,468.42,0,0,1-64.63-3.4q-.43-26.37-.85-52.73c16.76,2.27,34.65,4.06,53.57,5.1a679.75,679.75,0,0,0,98.65-1.7Z" />
                        <path className="cls-6" d="M363.46,404.8A247,247,0,0,1,190,404a459.89,459.89,0,0,1,1.08-195.41l164.58.54A603.83,603.83,0,0,1,368,339.62,602.34,602.34,0,0,1,363.46,404.8Z" />
                        <rect className="cls-7" x="391.39" y="248.86" width="61.01" height="10.23" rx="2.75" transform="translate(167.92 675.87) rotate(-90)" />
                        <rect className="cls-7" x="408.4" y="248.01" width="61.01" height="10.23" rx="2.75" transform="translate(185.78 692.02) rotate(-90)" />
                        <path className="cls-4" d="M380.57,153.48q-.93-8.37-1.45-17.59c-.41-7.52-.53-14.66-.44-21.4a21.59,21.59,0,0,1,1.89,39Z" />
                        <path className="cls-4" d="M170.87,153.67c.6-5.58,1.09-11.45,1.41-17.59.4-7.51.5-14.66.4-21.39a21.58,21.58,0,0,0-1.81,39Z" />
                        <path className="cls-6" d="M378.65,113.64c15.91,52.15-19,95.29-21.81,98.63l-167.43-2.8c-23.15-32-27-74-10-109,18.92-38.93,58.88-59.38,96.9-59.29C319.73,41.25,364.78,68.14,378.65,113.64Z" />
                        <path className="cls-7" d="M359.9,140.38c-3,40.58-41.62,70.09-79.88,72.3-42,2.41-87-27.91-90.34-72.13-3.49-45.83,39.3-85.15,85-85.2C320.73,55.31,363.27,95,359.9,140.38Z" />
                        <path className="cls-8" d="M323.56,70.81c24.06,16,38.3,42.57,36.34,69.57-2.56,35.42-32.62,66.26-72.5,71,32-9.14,55.83-35.3,61.05-67C355.32,102.8,326.32,73.51,323.56,70.81Z" />
                        <path className="cls-5" d="M367.51,218.36a219.28,219.28,0,0,1-90.41,20.31,219.84,219.84,0,0,1-99.13-23l10.52-7.54a191.77,191.77,0,0,0,171.67,1.14Z" />
                        <path className="cls-5" d="M228.08,596.17,190.24,596c.11-18.12,17.14-33,37.81-33s37.86,14.94,37.87,33.13Z" />
                        <path className="cls-5" d="M325.88,596.07,288,595.9c.12-18.11,17.15-33,37.82-33S363.7,577.84,363.72,596Z" />
                        <path className="cls-4" d="M103.7,178.77c-.4.13,1.33,16.31,12.59,30.94,10.93,14.2,25.52,20,25.76,19.66S128.79,223,117.6,208.32C106.27,193.47,104.08,178.64,103.7,178.77Z" />
                        <path className="cls-4" d="M67,73.82H78.18A16.47,16.47,0,0,1,94.65,90.29v35.4a0,0,0,0,1,0,0H50.5a0,0,0,0,1,0,0V90.29A16.47,16.47,0,0,1,67,73.82Z" />
                        <rect className="cls-4" x="81.34" y="105.53" width="26.52" height="11.92" rx="4.45" transform="translate(-50.45 129.92) rotate(-56.93)" />
                        <path className="cls-4" d="M533.77,221.27H545a16.47,16.47,0,0,1,16.47,16.47v35.4a0,0,0,0,1,0,0H517.3a0,0,0,0,1,0,0v-35.4a16.47,16.47,0,0,1,16.47-16.47Z" transform="translate(738.69 -311.64) rotate(85.01)" />
                        <rect className="cls-4" x="509.55" y="216.34" width="30.85" height="11.92" rx="4.45" transform="translate(1093.9 185.66) rotate(153.36)" />
                        <rect className="cls-7" x="487.98" y="242.89" width="49.51" height="10.21" rx="2.75" transform="translate(684.53 -295.04) rotate(81.74)" />
                        <rect className="cls-7" x="46.38" y="122.73" width="52.4" height="11.92" rx="4.45" />
                        <rect className="cls-7" x="75.85" y="223.55" width="71.13" height="11.92" rx="4.45" transform="translate(-105.76 95.62) rotate(-32.45)" />
                        <rect className="cls-7" x="189.27" y="472.62" width="77.39" height="11.92" rx="4.45" />
                        <rect className="cls-7" x="189.29" y="490.48" width="77.39" height="11.92" rx="4.45" />
                        <rect className="cls-7" x="287.07" y="472.52" width="77.39" height="11.92" rx="4.45" />
                        <rect className="cls-7" x="287.09" y="490.38" width="77.39" height="11.92" rx="4.45" />
                        <rect className="cls-7" x="287.18" y="590.84" width="77.39" height="7.56" rx="3.78" />
                        <rect className="cls-7" x="189.38" y="590.93" width="77.39" height="7.56" rx="3.78" />
                        <rect className="cls-7" x="65.11" y="210.16" width="71.13" height="11.92" rx="4.45" transform="matrix(0.84, -0.54, 0.54, 0.84, -100.25, 87.77)" />
                        <rect className="cls-7" x="543.75" y="66.33" width="8.5" height="531.51" rx="4.25" />
                    </g>
                </g>
            </svg>
        )
}

export default FlagSVG