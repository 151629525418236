import React from 'react'

const ServiceFeatures = () => {
    return (
        <div className="row property-server-card mt-5 mb-3">
            <div className="w-100 text-center">
                <h1 className="header-title-row"> ویژگی های سرویس ها </h1>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 55.97 62.23" className="SVG-network">
                            <g id="Layer_2" data-name="Layer 2"><g id="Layer_5" data-name="Layer 5">
                                <circle className="cls-1" cx="28.78" cy="31.22" r="8.8" />
                                <circle className="cls-1" cx="28.73" cy="55.13" r="6.1" />
                                <circle className="cls-1" cx="28.73" cy="7.1" r="6.1" />
                                <circle className="cls-1" cx="7.1" cy="19.29" r="6.1" />
                                <circle className="cls-1" cx="48.88" cy="19.29" r="6.1" />
                                <circle className="cls-1" cx="7.1" cy="41.03" r="6.1" />
                                <circle className="cls-1" cx="48.88" cy="41.03" r="6.1" />
                                <line className="cls-1" x1="28.78" y1="13.19" x2="28.78" y2="22.42" />
                                <line className="cls-1" x1="12.51" y1="22.1" x2="21.18" y2="26.79" />
                                <line className="cls-1" x1="12.79" y1="38.84" x2="20.97" y2="35.27" />
                                <line className="cls-1" x1="28.68" y1="49.04" x2="28.68" y2="40.02" />
                                <line className="cls-1" x1="44.12" y1="23.1" x2="36.39" y2="26.79" />
                                <line className="cls-1" x1="43.18" y1="38.84" x2="36.39" y2="35.65" />
                            </g>
                            </g>
                        </svg>
                        <h4 className=""> شبکه </h4>
                    </div>
                    <p>سرور های شما متصل به پورت های 10 گیگابیت هستند که قادر هستند اطلاعات شما را با سرعت بی نظیری در اینترنت انتقال دهند.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 57.84 63.21" className="SVG-valume">
                            <g id="Layer_2" data-name="Layer 2"><g id="Layer_5" data-name="Layer 5">
                                <path className="cls-1" d="M56.84,56.74a1.94,1.94,0,0,1-1.41,1.85,99.25,99.25,0,0,1-29.17,3.58A98.7,98.7,0,0,1,2.39,58.58,1.9,1.9,0,0,1,1,56.74V13.45A7.58,7.58,0,0,1,5.16,6.67a52.69,52.69,0,0,1,47.44,0,7.65,7.65,0,0,1,4.24,6.83Z" />
                                <path className="cls-1" d="M56.84,31.59a99.45,99.45,0,0,1-27.92,4A99.41,99.41,0,0,1,1,31.59V18.3a99.76,99.76,0,0,0,27.92,4,99.83,99.83,0,0,0,27.92-4Z" />
                                <path className="cls-1" d="M56.84,44.89a99.82,99.82,0,0,1-27.92,4A99.78,99.78,0,0,1,1,44.89V31.59a99.39,99.39,0,0,0,27.92,4,99.46,99.46,0,0,0,27.92-4Z" />
                                <path className="cls-1" d="M56.84,56.74a1.91,1.91,0,0,1-1.41,1.84,99.67,99.67,0,0,1-26.51,3.59A99.49,99.49,0,0,1,2.4,58.58,1.91,1.91,0,0,1,1,56.74V44.89a99.76,99.76,0,0,0,27.92,4,99.83,99.83,0,0,0,27.92-4Z" />
                            </g>
                            </g>
                        </svg>
                        <h4> فضای ذخیره سازی </h4>
                    </div>
                    <p>اطلاعات سرور شما بر روی بهترین اس اس دی های بازار ذخیره شده و شما در هر لحظه قادر به افزایش فضای سرور خود تا 10 ترابایت خواهید بود.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 51.51 58" className="SVG-productivity">
                            <g id="Layer_2" data-name="Layer 2"><g id="Layer_5" data-name="Layer 5">
                                <path className="cls-1" d="M.51,31l22-13,4,5,23-21" />
                                <path className="cls-1" d="M29.51,28V58" />
                                <path className="cls-1" d="M15.51,34V58" />
                                <path className="cls-1" d="M2.51,40V58" />
                                <path className="cls-1" d="M43.51,15V58" />
                                <path className="cls-1" d="M50.51,1V1Z" />
                                <line className="cls-1" x1="40.51" x2="50.51" y2="2" y1="4" />
                            </g>
                            </g>
                        </svg>
                        <h4> کارایی </h4>
                    </div>
                    <p>سرور های شما بر روی قدرتمند ترین سخت افزای های بازار میزبانی خواهند شد. Intel® Xeon® Gold، AMD Milan™ Epyc 7003، NVMe SSD و پورت های 10 گیگابیتی.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 74.5 61" className="SVG-snapshot">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_5" data-name="Layer 5">
                                    <path className="cls-1" d="M68.43,12.5H52.85L50.21,3.44A3.38,3.38,0,0,0,47,1H29.85a3.41,3.41,0,0,0-3.18,2.2l-3.48,9.3H6.07A5.07,5.07,0,0,0,1,17.57V54.93A5.07,5.07,0,0,0,6.07,60H68.43a5.07,5.07,0,0,0,5.07-5.07V17.57A5.07,5.07,0,0,0,68.43,12.5Z" />
                                    <circle className="cls-1" cx="37.25" cy="37.25" r="14.25" />
                                    <circle className="cls-1" cx="37.25" cy="37.25" r="10.75" />
                                </g>
                            </g>
                        </svg>
                        <h4 className=""> اسنپ شات </h4>
                    </div>
                    <p>با استفاده از قابلیت اسنپ شات قادر خواهید بود نسخه کپی از سرور خود تهیه کنید و سرور های جدیدتری از روی ان ایجاد کنید.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 70 56" className="SVG-OS">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="_3-features" data-name="3-features">
                                    <rect className="cls-1" x="1" y="1" width="68" height="54" rx="10.79" />
                                    <line className="cls-1" x1="1" y1="16.11" x2="69" y2="16.11" />
                                </g>
                                <g id="Layer_5" data-name="Layer 5">
                                    <text className="cls-2" transform="translate(35 44.94)">OS</text>
                                </g>
                            </g>
                        </svg>
                        <h4 className=""> سیستم عامل </h4>
                    </div>
                    <p>تنوع بالایی از سیستم عامل ها در اختیار شما خواهد بود که البته چندین نسخه از هر کدام در هر لحظه قابل انتخاب خواهند بود.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 89.66 56" className="SVG-IP">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_5" data-name="Layer 5">
                                    <rect className="cls-1" x="31.86" y="1" width="26.65" height="26.65" rx="2.34" />
                                    <rect className="cls-1" x="1" y="43.08" width="11.92" height="11.92" rx="2.34" />
                                    <rect className="cls-1" x="39.57" y="43.08" width="11.92" height="11.92" rx="2.34" />
                                    <rect className="cls-1" x="76.74" y="43.08" width="11.92" height="11.92" rx="2.34" />
                                    <line className="cls-1" x1="13.62" y1="48.69" x2="39.57" y2="48.69" />
                                    <line className="cls-1" x1="52.19" y1="48.69" x2="76.04" y2="48.69" />
                                    <line className="cls-1" x1="45.53" y1="43.08" x2="45.53" y2="27.65" />
                                    <text className="cls-2" transform="translate(50.66 20.94)">IP</text>
                                </g>
                            </g>
                        </svg>
                        <h4 className=""> آپی های شناور </h4>
                    </div>
                    <p>امکان تهیه آی پی ها شناور برای اتصال آن ها به سرور ها مختلف در هر لحظه نیز از طریق پنل فراهم شده است.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 82.85 44.91" className="SVG-backup">
                            <g id="Layer_2" data-name="Layer 2"><g id="_4-pricing" data-name="4-pricing">
                                <line className="cls-1" x1="40.67" y1="22.64" x2="40.67" y2="44.91" />
                                <path className="cls-1" d="M53,43.72H71.17A10.68,10.68,0,0,0,81.85,33V31.8A10.7,10.7,0,0,0,73.1,21.29c0-.29,0-.59,0-.9a11.71,11.71,0,0,0-18.83-9.31A15.56,15.56,0,0,0,26.77,8a12.4,12.4,0,0,0-3.3-.45,12,12,0,0,0-12,12,12.17,12.17,0,0,0,.52,3.54h-.28A10.57,10.57,0,0,0,1,33.87a10.58,10.58,0,0,0,11.74,10l11.75-.12h2.67" />
                                <path className="cls-1" d="M36.85,26.45c1.27-1.48,2.54-3,3.82-4.45l3.18,4.45" />
                            </g>
                            </g>
                        </svg>
                        <h4 className=""> بک آپ </h4>
                    </div>
                    <p>با پرداخت درصدی از هزینه سرور قادر خواهید بود تا 7  نسخه بک آپ از سرور خود تهیه و در هر زمان آن ها را به سرعت بازیابی کنید.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 65 76.11" className="SVG-support">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_5" data-name="Layer 5">
                                    <circle className="cls-1" cx="33" cy="21.94" r="17.89" />
                                    <circle className="cls-1" cx="35.52" cy="30" r="2.27" />
                                    <path className="cls-2" d="M37.79,28.86c5.21-1.21,10.92-2.43,16.13-3.65C55,12.24,44.68,1.53,33.76,1,22,.47,10.69,11.74,12.09,25.72" />
                                    <path className="cls-1" d="M53.49,17.16a4.13,4.13,0,0,1,.43,8.05" />
                                    <path className="cls-1" d="M12.59,17.66a4.13,4.13,0,0,0-.42,8.06" />
                                    <path className="cls-2" d="M64,75.11a31.5,31.5,0,0,0-63,0Z" />
                                </g>
                            </g>
                        </svg>
                        <h4 className=""> پشتیبانی </h4>
                    </div>
                    <p>شما در هر لحظه قادر به ارتباط با بخش پشتیبانی و کارشناسان ما برای حل مشکل، راهنمایی و درخواست سرویس های اضافه خواهید بود.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 65 65" className="SVG-traffic">
                            <g id="Layer_2" data-name="Layer 2"><g id="Layer_5" data-name="Layer 5">
                                <rect className="cls-1" x="1" y="1" width="63" height="63" rx="12" />
                                <rect className="cls-1" x="10" y="37.93" width="9.2" height="15.89" />
                                <rect className="cls-1" x="19.2" y="31.24" width="9.2" height="22.58" />
                                <rect className="cls-1" x="28.4" y="23.71" width="9.2" height="30.11" />
                                <rect className="cls-1" x="37.6" y="17.85" width="9.2" height="35.96" />
                                <rect className="cls-1" x="46.8" y="12" width="9.2" height="41.82" />
                            </g>
                            </g>
                        </svg>
                        <h4 className=""> ترافیک </h4>
                    </div>
                    <p>
                        سرور های ابری شامل 20 ترابایت ترافیک رایگان خواهند بود که پاسخگوی اکثر نیاز ها شما خواهد بود. با این حال امکان تهیه ترافیک پیشتر نیز فراهم است.
                    </p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 55.77 66.62" className="SVG-ddoc">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_5" data-name="Layer 5">
                                    <path className="cls-1" d="M29.69,64.88a3.6,3.6,0,0,1-4.42,0A63.85,63.85,0,0,1,9.47,46.67C-.18,29.94.46,13,1.68,4.17A3.61,3.61,0,0,1,6.44,1.24a64.64,64.64,0,0,0,22,3.63A64.35,64.35,0,0,0,49.2,1.2,3.61,3.61,0,0,1,54,4.07,64.16,64.16,0,0,1,49.43,40,63.73,63.73,0,0,1,29.69,64.88Z" />
                                    <path className="cls-1" d="M29.19,57.63a2.79,2.79,0,0,1-3.4,0A49.53,49.53,0,0,1,13.5,43.48c-7.51-13-7-26.21-6-33.07A2.76,2.76,0,0,1,11.12,8.2,50.13,50.13,0,0,0,28.19,11,49.92,49.92,0,0,0,44.38,8.17,2.76,2.76,0,0,1,48,10.34a49.82,49.82,0,0,1-3.49,27.93A49.66,49.66,0,0,1,29.19,57.63Z" />
                                    <line className="cls-2" x1="28.27" y1="33.5" x2="28.27" y2="38.27" />
                                    <line className="cls-2" x1="28.27" y1="43.72" x2="28.27" y2="48.5" /><text className="cls-3" transform="translate(44.77 30.43)">DDOS</text>
                                    <line className="cls-2" x1="25.54" y1="41" x2="20.77" y2="41" />
                                    <line className="cls-2" x1="35.77" y1="41" x2="31" y2="41" /><circle className="cls-2" cx="28.27" cy="41" r="4.77" />
                                </g>
                            </g>
                        </svg>
                        <h4 className=""> محافظت از حملات </h4>
                    </div>
                    <p>سرور های شما تحت حفاظت دائمی در مقابل حملات مختلف و حجم بالا با استفاده از سخت افزار ها و نرم افزار های روز دنیا هستند.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 83 83" className="SVG-control">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_5" data-name="Layer 5">
                                    <circle className="cls-1" cx="41.5" cy="41.5" r="40.5" />
                                    <line className="cls-2" x1="21.48" y1="47.55" x2="62.45" y2="47.55" />
                                    <line className="cls-2" x1="21.48" y1="58.26" x2="62.45" y2="58.26" />
                                    <circle className="cls-3" cx="29.16" cy="47.32" r="4.42" />
                                    <circle className="cls-3" cx="41.73" cy="57.56" r="4.42" />
                                    <line className="cls-2" x1="21.48" y1="68.03" x2="62.45" y2="68.03" />
                                    <circle className="cls-3" cx="29.16" cy="68.27" r="4.42" />
                                    <path className="cls-2" d="M31,9.55,36.3,6.5a.42.42,0,0,1,.49.06c1.15,1.1,6.67,5.86,10,.15a.41.41,0,0,1,.56-.17l5.8,3a.4.4,0,0,1,.22.45c-.34,1.64-1.59,9.29,5,8.19a.36.36,0,0,1,.42.37v9a.4.4,0,0,1-.34.4c-1.47.23-7,1.52-4.82,8.3a.46.46,0,0,1-.2.53l-5.57,3a.41.41,0,0,1-.49-.07c-1.1-1.17-6.18-6.06-9.68-.15a.4.4,0,0,1-.57.15L31,36a.43.43,0,0,1-.18-.48c.48-1.51,2.21-8.24-5.24-7.33a.42.42,0,0,1-.47-.41V18.84a.43.43,0,0,1,.42-.44c1.63-.08,7.45-.9,5.29-8.37A.41.41,0,0,1,31,9.55Z" />
                                    <circle className="cls-1" cx="42.04" cy="23.3" r="8" />
                                </g>
                            </g>
                        </svg>
                        <h4 className=""> کنترل پنل پیشرفته </h4>
                    </div>
                    <p>سرور های شما از طریق کنترل پنلی کامل و پیشرفته قابل مدیریت هستند و تغییرات در هر لحظه و بدون نیاز به ارتباط با بخش پشتیبانی قابل انجام هستند.</p>
                </div>
            </div>

            <div className="property-card p-3">
                <div className="landing-card">
                    <div>
                        <svg viewBox="0 0 51.78 78.8" className="SVG-location">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_5" data-name="Layer 5">
                                    <path className="cls-1" d="M33.74,61.62a10.08,10.08,0,1,1-16-.06" />
                                    <path className="cls-1" d="M50.25,22.1c2.28,10.18-3.08,18.92-9,28.64A77.06,77.06,0,0,1,27.55,67.26a2.14,2.14,0,0,1-2.86,0A79.48,79.48,0,0,1,9.55,49.23C4.23,40.52.29,34.05,1.11,25.87,2.21,14.87,11.89,1,26,1,38.74,1,48.05,12.31,50.25,22.1Z" />
                                    <circle className="cls-1" cx="26.13" cy="25.12" r="12.96" />
                                    <circle className="cls-1" cx="26.13" cy="25.12" r="6.63" />
                                </g>
                            </g>
                        </svg>
                        <h4 className=""> لوکیشن </h4>
                    </div>
                    <p>سرور های شما در دیتاسنتر های هتزنر در دو کشور آلمان و فنلاند میزبانی می شوند که با استاندارد های روز دنیا مدیریت و نگهداری می شوند.</p>
                </div>
            </div>
        </div>

    )
}

export default ServiceFeatures