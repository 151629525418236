import React from 'react';
import Footer from '../components/footer'
import TermsHeader from '../components/termsheader'
import AgreementSVG from '../components/svgs/agreementSVG'


const TermsPage = (props) => {

    const refHandler = (key) => {
        props.history.push(`/#${key}`)
    }

    return (
        <div className="landing-page terms-page">
            {/* header */}
            <TermsHeader refHandler={refHandler} />

            <h3> توافق نامه </h3>
            {/* bookSVG */}
            <AgreementSVG />

            {/* content */}
            <div className="padding-cheker">
                <div className="terms row m-0">
                    <div className="col-lg-3 col-md-3 terms-appendix">
                        <div className="appendix">
                            <h2>فهرست</h2>
                        </div>
                        <div className="appendix-content">
                            <a href="#title1"> تعاريف </a>
                            <a href="#title2"> هویت </a>
                            <a href="#title3"> نحوه ارتباط طرفين با هم </a>
                            <a href="#title4"> تغييرات </a>
                            <a href="#title5"> تحويل خدمات </a>
                            <a href="#title6"> امنيت و محرمانگي </a>
                            <a href="#title7"> مرجع قضاوت </a>
                            <a href="#title8"> فعاليتهاي غير مجاز خريدار </a>
                            <a href="#title9"> محدوده استفاده از منابع CPU , Disk space , Traffic Data transfer , Ram, و ... </a>
                            <a href="#title10"> محتوا </a>
                            <a href="#title11"> مسئوليت خريدار </a>
                            <a href="#title12"> گارانتي و پشتيباني </a>
                            <a href="#title13"> ضوابط خاص ميزباني </a>
                            <a href="#title14"> ساير ضوابط خاص </a>
                            <a href="#title15"> نسخه پشتيبان (Backup) </a>
                            <a href="#title16"> شرايط و قوانين مالي </a>
                            <a href="#title17"> فورس ماژور </a>
                            <a href="#title18"> انقضاء و تمديد خدمات </a>
                            <a href="#title19"> پذيرش مواد توافقنامه و ضوابط </a>
                            <a href="#title20"> رویه حفاظت از اطلاعات </a>
                            <a href="#title21"> خسارت و رفع مسئوليت </a>
                            <a href="#title22"> سلب اختيار </a>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 terms-description">
                        <div className="description">
                            <h2>توضیحات</h2>
                        </div>
                        <div className="description-content">
                            <p><strong>* کاربر گرامی؛ لطفاً موارد زیر را جهت استفاده بهینه از خدمات آرویکس به دقت ملاحظه فرمایید ... *</strong></p>
                            {/* ----------------------------- */}
                            <h2 id="title1"> فصل اول : تعاريف </h2>
                            <ol className="list-style-number">
                                <li>
                                    خریدار: شخصی است که به عنوان شخص حقیقی یا حقوقی اقدام به خرید و پرداخت هزینه سرویس می‌نماید.
                                </li>
                                <li>
                                    سرویس: هر یک از خدمات ثبتی، نرم‌افزاری، فنی و ارتباطی قابل ارائه توسط وب سایت
                                </li>
                                <li>
                                    وب سایت آرویکس: وبگاهی به آدرس arvix.cloud که مشخصات خریدار در حساب کاربری آن ثبت شده و قابل ویرایش و به‌روزرسانی است و می‌تواند فهرست سرویس‌های خود را مشاهده، تمدید یا ارتقا دهد.
                                </li>
                                <li>
                                    تعرفه: فهرست کامل سرویس‌های قابل ارائه همراه با ویژگی‌ها و محدودیت‌ها و قیمت‌ها، به‌نحوی‌ که خریدار کاملاً آگاه گردد.
                                </li>
                                <li>
                                    Spam (هرزنامه): ارسال ایمیل به فردی، بدون اینکه گیرنده موافق دریافت آن باشد.
                                </li>
                                <li>
                                    Spam Cop: وب‌سایت شناسایی و مقابله با اسپم Spamcop.net
                                </li>
                                <li>
                                    IDC: مرکز خدمات داده‌ای اینترنت Internet Data Center
                                </li>
                                <li>
                                    تحویل خدمات: ارائه شناسه یا کلمه عبور استفاده از سرویس‌های خریداری‌شده و یا اعلام انجام سایر خدمات بر اساس فاکتور یا قرارداد بین خریدار و آرویکس.
                                </li>
                                <li>
                                    کاربر وب‌سایت: کلیه اشخاصی که از سراسر دنیا وب‌سایت خریدار را مشاهده و مرور می‌کنند.
                                </li>
                                <li>
                                    منابع RAM: میزان استفاده از حافظه اصلی سرور یا سرورها برحسب مگابایت که در هنگام استفاده و مشاهده کاربران از وب‌سایت خریدار مصرف می‌شود.

                                </li>
                                <li>
                                    منابع CPU: میزان استفاده از پردازنده سرور یا سرورهایی که جهت پردازش درخواست‌ها و اجرای کدهای داینامیک آن سایت نسبت به کل توان پردازنده سرور به‌صورت درصد در زمان استفاده و مشاهده کاربران، به وب‌سایت خریدار سرویس‌دهی می‌کنند

                                </li>
                                <li>
                                    space Disk: میزان فضایی که برای ذخیره کلیه فایل‌های خریدار اعم از وب‌سایت، ایمیل‌ها، پایگاه‌های داده و همچنین لاگ‌های آن‌ها توسط یک وب‌سایت مصرف می‌گردد.
                                </li>
                                <li>
                                    Bandwidth: میزان ردوبدل اطلاعات بین کاربران وب‌سایت و سرور جهت نمایش و عملکرد یک وب‌سایت برحسب مگابیت بر ثانیه. همچنین میزان ردوبدل اطلاعات بین دو سرور درصورتی‌که این اطلاعات به‌منظور سرویس‌دهی به کاربران آن وب‌سایت باشد.
                                </li>
                                <li>
                                    Data Transfer: میزان تجمعی ردوبدل اطلاعات بین کاربران وب‌سایت و سرور یا سرورهای سرویس‌دهنده به وب‌سایت خریدار برحسب مگابایت
                                </li>
                                <li>
                                    بالا بودن سرور: در دسترس بودن عمومی سرور در شبکه‌ اینترنت در حداقل ۲ نقطه مختلف از دنیا بسته به سرویس‌های مختلفی که روی یک سرور قرار دارد باید پاسخ تست‌های زیر مثبت باشد:
                                    <ul className="list-style-circle pl-2 pr-3">
                                        <li>
                                            وب سرور: ping عمومی سرور یا پاسخ‌دهی سرور روی پورت ۸۰ از طریق telnet
                                        </li>
                                        <li>
                                            ایمیل سرور: ping عمومی سرور یا پاسخ‌دهی سرور روی پورت ۲۵ یا ۱۱۰ از طریق telnet
                                        </li>
                                        <li>
                                            دیتابیس سرور: پاسخ‌دهی سرور روی پورت MS sql server و My sql
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Uptime: نسبت میزان زمانی که کلیه سرویس‌های سرویس‌دهنده به وب‌سایت خریدار در شبکه‌ اینترنت بالا می‌باشند به کل مدت قرارداد سرویس‌دهی به درصد، درصورتی‌که وب‌سایت یا برخی از سرویس‌ها به دلیل عدم رعایت بندهای این قرارداد به حالت تعلیق درآیند در این زمان محاسبه نمی‌شود.
                                </li>
                            </ol>
                            {/* ----------------------------- */}
                            <h2 id="title2"> فصل دوم : هويت </h2>
                            <p>
                                1 - هویت خریدار: خریدار شخصی حقیقی یا حقوقی است که مشخصات وی در پرتال کاربران و در بانک اطلاعات کاربران آرویکس وجود دارد و سرویس‌های خریداری‌شده تحت حساب وی مدیریت می‌شود.
                            </p>
                            <p>
                                2 - در مورد خریداران با هویت شخص حقوقی، یک نفر نماینده تام‌الاختیار در پرتال کاربران معرفی می‌گردد. این نماینده نسبت به سرویس تهیه شده همانند خود شخص حقوقی اختیار تام دارد. وظیفه اعلام نماینده جدید در آینده بر عهده خریدار بوده و آرویکس در صورت عدم دریافت اعلام ، مسئولیتی نسبت به حقوق و عملکرد مالکیتی و مسئولیتی خریدار نخواهد داشت.
                            </p>
                            <p>
                                3 - برای حفظ حقوق قانونی خریداران، کلیه خریداران موظف به ارائه اطلاعات هویتی خود شامل کپی شناسنامه، کپی کارت ملی برای اشخاص حقیقی و درخواست شخص حقوقی همراه با کپی روزنامه رسمی آخرین تغییرات، به آرویکس می‌باشند. در صورتی که آرویکس، در هنگام تکمیل فرآیند خرید و به جهت تسهیل و تسریع امور، اقدام به دریافت و کنترل این مدارک ننماید پس از آن حق دریافت این مدارک همواره برای آرویکس محفوظ بوده و با عدم ارائه مدارک در زمان مقرر به آرویکس، حقوق مالکیتی خریدار ملغی محسوب می‌شود و ضمناً عواقب اعلام ناصحیح اطلاعات مستقیماً بر عهده خریدار خواهد بود. آرویکس مسئولیتی در قبال کنترل صحت امضاء خریداران و نمایندگان آن‌ها ندارد.
                            </p>
                            <p>
                                4 - خریدار اذعان می‌نماید که کلیه اطلاعات هویتی ارائه شده و آدرس‌ها و شماره تماس و امثالهم صحیح بوده و همواره در بروز نگه‌داشتن آن‌ها در پرتال کاربران می‌کوشد. آرویکس حق دارد هر زمان که نیاز به کنترل این اطلاعات داشته تصویر برابر اصل این اطلاعات را از خریدار دریافت نماید. آرویکس حق دارد تا تکمیل اطلاعات صحیح و مستند نسبت به تعلیق سرویس اقدام نماید.
                            </p>
                            <p>
                                5 - مالکیت سرویس و کلیه مسئولیت‌ها و حقوق مرتبط با آن، بر عهده خریدار و یا نماینده خریدار هریک به تنهایی است.
                            </p>
                            <p>
                                6 - در صورتی که به درخواست مشتری و در راستای کاهش هزینه و یا بهبود کیفی خدمات نگهداری و تمدید، به جای مشخصات خریدار مشخصات شرکت ارائه کننده خدمات، ثبت شده باشد همچنان مالکیت و کلیه حقوق و مسئولیت‌های قانونی بر عهده خریدار خواهد بود.
                            </p>
                            {/* ------------------------------- */}
                            <h2 id="title3"> فصل سوم : نحوه ارتباط طرفين با هم </h2>
                            <p>
                                ۱ - روش ارتباطی مستند آرویکس با خریدار، آدرس ایمیل خریدار و پاسخ به تیکت‌هایی است که از طریق حساب کاربری ثبت شده‌اند.
                            </p>
                            <p>
                                ۲ - روش ارتباطی مستند خریدار با آرویکس، ارسال ایمیل یا ثبت تیکت در حساب کاربری و دریافت شماره پیگیری تیکت خواهد بود.
                            </p>
                            <p>
                                ٣ - آرویکس مسئولیتی نسبت به تبعات و خسارات وارد بر خریداری که اطلاعات تماس تغییریافته خود را از طرق معتبر به اطلاع آرویکس نرسانده است ندارد.
                            </p>
                            {/* ------------------------------- */}
                            <h2 id="title4"> فصل چهارم : تغييرات </h2>
                            <p>
                                ۱ - تغییرات توافقنامه: توافقنامه استفاده از خدمات آرویکس تابعی است از قوانین جمهوری اسلامی ایران و سایر قوانین بین‌المللی و توافقات مجامع بین‌المللی ناظر بر سرویس‌های قابل ارائه آرویکس و از آنجا که این قوانین و توافقات در سطح کشور و بین‌الملل به طور آنلاین و ماهانه امکان تغییر دارند، آرویکس حق تغییرات آتی در توافقنامه را بر خود محفوظ می‌داند و نسخه توافقنامه بروز شده را از طریق لینک به صفحه توافقنامه در وب‌سایت آرویکس در دسترس عموم خواهد بود. ضمناً آرویکس مسئولیتی نسبت به اطلاع‌رسانی نفر به نفر در خصوص تغییرات احتمالی نداشته و خریدار می‌بایست موارد را عندالزوم با مراجعه به وب‌سایت آرویکس کنترل نماید.
                            </p>
                            <p>
                                ۲ - تغییرات تعرفه:
                                <ul className="list-style-circle mr-5 mt-3">
                                    <li>
                                        در صورت تغییر تعرفه در حین استفاده از سرویس، خریدار در صورت تمایل به ارتقاء در سرویس فعلی خود موظف به رعایت قیمت‌های جدید خواهد بود. لیکن خدمات خریداری‌شده با تعرفه قبلی تا پایان دوره ارائه می‌گردد.
                                    </li>
                                    <li>
                                        هزینه‌های کارشناسی نیروی فنی و یا سایر خدماتی که در هنگام استفاده از یک سرویس اولیه خریداری‌شده مورد نیاز واقع می‌گردد، تابع تعرفه روز درخواست جدید خریدار است.
                                    </li>
                                    <li>

                                        تغییرات تعرفه از طریق وب‌سایت منعکس شده و خریدار موظف به تبعیت از آن برای سفارش‌های جدید است مگر اینکه پیش‌فاکتور مهلت داری در اختیار داشته باشد که قبل از تغییرات تعرفه برای وی صادر گشته باشد.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                ۳ - تغییرات فنی: آرویکس برای بهبود کیفیت خدمات حق انجام تغییرات فنی، سخت‌افزاری، نرم‌افزاری یا شبکه‌ای را در سرویس‌های ارائه شده دارد. در مورد تغییراتی که سرویس خریدار را تحت تأثیر قرار می‌دهد، آرویکس موظف است از یک روز تا یک ماه قبل از اعمال تغییرات، از طریق وب‌سایت آرویکس، پرتال کاربران یا پرتال پشتیبانی و یا ارسال خبرنامه به آدرس Email خریداران اطلاع‌رسانی نماید. خریدار موظف است خود را با این تغییرات سازگار نماید. هرگاه به دلیل موارد ضروری، آرویکس امکان اطلاع‌رسانی را از دست بدهد و یا فوریت‌های فنی وی را ناچار به این امر نماید مسئولیتی برای اطلاع‌رسانی زمان و نحوه تغییرات نخواهد داشت. ضمناً خریدار به آرویکس وکالت می‌دهد تا در زمان مورد نیاز در جهت بهبود سطح کیفی سرویس اقدام به انجام فعالیت‌های سخت‌افزاری و نرم‌افزاری روی سرویس های آن نماید.
                            </p>
                            <p>
                                ۴ - تغییرات مالکیت: تغییرات مالکیت سرویس از طریق درخواست خریدار یا نماینده وی و یا ارسال ایمیل درخواست مذکور از طریق آدرس ایمیل موجود در حساب کاربری در پرتال کاربران انجام می‌گردد.
                                <ul className="list-style-circle mr-5 mt-3">
                                    <li>
                                        آرویکس در راستای حفظ حقوق خریداران خود، حق دارد مدارک شناسایی متقاضی تغییر مالکیت را جهت احراز هویت وی و انطباق با مشخصات مالک سرویس دریافت دارد.
                                    </li>
                                    <li>
                                        آرویکس می‌تواند تا زمانی که اطلاعات کافی در مورد موارد مشکوک پیدا نکرده است، اقدامی برای تغییر مالکیت یک سرویس صورت ندهد.
                                    </li>
                                </ul>
                            </p>
                            {/* -------------------------------- */}
                            <h2 id="title5"> فصل پنجم : تحويل خدمات </h2>
                            <ol className="list-style-number">
                                <li>
                                    نحوه تحویل خدمات:
                                    <ul className="list-style-circle pl-2 pr-3">
                                        <li>
                                            پس از پرداخت مبلغ سفارش توسط خریدار، با آماده تحویل شدن سرویس خریداری‌شده، مراتب از طریق ایمیل مندرج در پروفایل و یا پنل کاربری به خریدار اطلاع داده می‌شود. عدم دسترسی خریدار به آدرس ایمیل مورد نظر مانع تحویل سرویس نمی‌گردد.
                                        </li>
                                        <li>
                                            خریدار در صورتی که در بازه زمانی حداکثر زمان تحویل، ایمیل مذکور را دریافت ننماید، موظف است حداکثر ۲ روز پس از پایان زمان تحویل مجاز، مراتب را به آرویکس اعلام نماید.
                                        </li>
                                        <li>
                                            در صورت بروز شرایط ویژه فنی و یا محدودیت‌هایی که تحویل در زمان مقرر آن انجام نشود، آرویکس مجاز به افزایش زمان تحویل سرویس تا ۲ برابر خواهد بود.
                                        </li>
                                        <li>
                                            آرویکس مسئولیتی در قبال عدم تطابق با نیاز و یا عدم استفاده خریدار از سرویس خریداری‌شده و تحویل شده ندارد.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    حداکثر زمان تحویل:
                                    <ul className="list-style-circle pl-2 pr-3">
                                        <li>
                                            حداکثر زمان تحویل برای سرویس‌های آرویکس که با عنایت به شرایط عمومی کشور و با فرض بدترین حالات ممکن، 3 روز کاری می باشد.
                                        </li>
                                        <li>
                                            در صورت عدم انجام سفارش در محدوده حداکثر زمان تعریف شده، مشتری موظف به اعلام به آرویکس است.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p>
                                ۳ - تطابق با سفارش:
                                در صورت وجود هرگونه مغایرت سرویس ارائه شده به خریدار با مشخصات سرویس، خریدار موظف است مراتب را حداکثر ظرف مدت یک هفته از تاریخ فاکتور به آرویکس اعلام نموده و آرویکس موظف است مراتب اصلاح را در اسرع وقت به عمل آورد، در غیر این صورت مسئولیت آرویکس صرفاً محدود به اصلاح سرویس پس از تاریخ اعلام خریدار و فقط تا پایان زمان سرویس مندرج در فاکتور است و زمان سرویس‌دهی افزایشی نخواهد یافت.
                            </p>
                            {/* -------------------------------- */}
                            <h2 id="title6"> فصل ششم : امنيت و محرمانگي </h2>
                            <p>
                                ۱ - به طور کلی محیط اینترنت به همان میزان سایر محیط‌های ارتباطی مانند پست، دورنگار و تلفن برای انتقال اطلاعات محرمانه، امنیت دارد. در کلیه این محیط‌ها ممکن است اطلاعات استراق سمع شوند. به همین دلیل ضمن آنکه آرویکس، تمام تلاش خود را برای ایجاد محیطی امن برای نگهداری و تبادل اطلاعات به‌صورت محرمانه می‌نماید، با این حال از جانب احتیاط از خریداران می‌خواهد که همیشه ارتباطات آنلاین اینترنتی خود را نا امن فرض نمایند. آرویکس هیچ‌گونه مسئولیتی در رابطه با امنیت تبادل اطلاعات از شبکه و سرورهای خود با جاهای دیگر را نمی‌پذیرد.
                            </p>
                            <p>
                                ۲ - آرویکس با تدابیر امنیتی خود مسئول حفظ امنیت پایه سرویس‌های خود است.
                            </p>
                            <p>
                                ۳ - آرویکس مسئولیتی نسبت به ضعف ایمنی ناشی از نرم‌افزارها، application ها و یا برنامه‌های مورد استفاده خریدار ندارد.
                            </p>
                            <p>
                                ۴ - آرویکس مسئولیتی نسبت به امنیت تبادل داده، بین خریدار و سرورهای خود ندارد.
                            </p>
                            <p>
                                ۵ - آرویکس مسئولیتی نسبت به در اختیار گرفتن کلمه عبور سرویس‌های خریدار، توسط اشخاص ثالث ندارد.
                            </p>
                            <p>
                                ۶ - آرویکس موظف به رعایت محرمانگی کلیه داده‌ها و نرم‌افزارهای موجود در سرویس خریدار است و همچنین آن را در اختیار اشخاص ثالث قرار نخواهد داد.
                            </p>
                            <p>
                                ۷ - آرویکس تلاش خود را برای حصول بالاترین سطح امنیت ممکن برای حساب آنلاین خریداران انجام می‌دهد، از این‌رو آرویکس نسبت به اموری که در حیطه مسئولیت خریدار از لحاظ نگهداری امنیت حساب خود است، مسئولیتی ندارد.
                            </p>
                            <p>
                                ۸ - در صورت نیاز مشتری جهت برطرف کردن مشکلات نرم‌افزاری و امنیتی application، آرویکس بنا به حجم کار، مجاز به دریافت هزینه اضافه جهت رفع عیب نرم‌افزاری مشتری است.
                            </p>
                            {/* ------------------------------ */}
                            <h2 id="title7"> فصل هفتم : مرجع قضاوت </h2>
                            <p>
                                در صورت بروز اختلاف، جهت رفع اختلاف، خریدار موظف است، بدواً نسبت به اعلام موضوع به واحد روابط عمومی و یا دفتر مدیریت آرویکس اقدام نموده و رسید کتبی دریافت نماید. تا موضوع مورد رسیدگی واقع گردد، در صورت عدم حصول نتیجه پس از ۱۵ روز کاری، خریدار می‌تواند به مراجع قضایی و انتظامی مراجعه نموده تا موضوع را مورد بررسی و رسیدگی قرار دهند.
                            </p>
                            {/* ------------------------------ */}
                            <h2 id="title8"> فصل هشتم : فعاليتهاي غير مجاز خريدار </h2>
                            <p>
                                تخطی از هر یک از شرایط درج شده در ذیل موجب تعلیق سرویس می‌شود. آرویکس تنها تشخیص دهنده تخطی از شرایط مندرج است. آرویکس حق قطع سرویس بدون هیچ اخطار قبلی و یا اجتناب از سرویس‌دهی را برای خود محفوظ می‌دارد. زمانی که آرویکس از تخطی یک خریدار از شرایط مندرج اطلاع پیدا می‌کند، نسبت به بررسی فنی و یا حقوقی موضوع اقدام خواهد کرد. در این زمان ممکن است برای جلوگیری از ادامه تخلف سرویس خریدار به‌صورت موقت قطع گردد. پس از تکمیل بررسی‌ها، آرویکس بسته به نوع تخلف مجاز به محدودسازی، تعلیق یا قطع سرویس خاطی خواهد بود. همچنین آرویکس در صورت لزوم حق پیگرد قانونی شکایت و اخذ خسارت از متخلف را نیز برای خود محفوظ می‌دارد. هرگونه عملی که در اینجا به آن اشاره نشده باشد ولی از نظر قوانین جاری کشور ایران یا کشور محل قرارگیری سرورها خلاف باشد نیز تخلف از شرایط خدمات محسوب می‌گردد. آرویکس هیچ‌گونه وجهی به خریدار متخلف از شرایط خدمات، تحت هر عنوان مانند ادعای خسارت یا هزینه مدت باقیمانده سرویس پرداخت نخواهد نمود. همچنین خریدار متخلف مسئول جبران کلیه خسارات مادی و معنوی وارده به آرویکس، سایر مشتریان آرویکس و یا IDC سرویس مورد نظر خواهد بود.
                            </p>
                            <ul className="list-style-circle pl-2 pr-3">
                                <li>
                                    استفاده از نرم‌افزارهای بدون رعایت حق کپی‌رایت ایران و بین‌المللی
                                </li>
                                <li>
                                    نصب و یا استفاده برنامه‌ای که موجب اختلال در عملکرد سرور گردد.
                                </li>
                                <li>
                                    ارسال یا اجرای فایل‌های اجرایی مانند فایل‌هایی با پسوند exe, com, bat, vbs و امثالهم و در صورت وجود این فایل‌ها بدون اخطار از فضای خریدار حذف می‌شود و با خریدار بر طبق ضوابط برخورد می‌گردد.
                                </li>
                                <li>
                                    خریدار مجاز به استفاده از کدهای مخرب مانند انواع ویروس‌ها و یا سورس‌هایی که موجب آلودگی یا پایین آمدن امنیت سرور گردد نیست.
                                </li>
                                <li>
                                    استفاده از برنامه‌های ضعیف نگارشی که موجب نفوذ به سرویس و سایر سرویس‌های دیگر آرویکس و سایر خریداران گردد.
                                </li>
                                <li>
                                    وجود فایل‌های حاوی ویروس عمداً و یا سهواً در فضای سرویس مورد خریداری
                                </li>
                                <li>
                                    ارسال هرزنامه (Spam) عمداً و یا سهواً (ملاک، گزارش Spam cop یا سایت‌های مشابه و یا بررسی تیم فنی آرویکس است) حتی اگر از سرویس میل سرویس‌دهنده ثالثی استفاده شده باشد.
                                </li>
                                <li>
                                    ارسال حداکثر ۵ ایمیل در ساعت مجاز است.
                                </li>
                                <li>
                                    ارسال ایمیل به‌صورت گروهی و تعداد بالا به هر صورت و شکل ممکن ممنوع است.
                                </li>
                                <li>
                                    ملاک ایمیل گروهی شباهت ۲ ایمیل خواهد بود.
                                </li>
                                <li>
                                    گزارش فنی آرویکس و یا IDC که سرور میزبانی در آن قرار دارد، نسبت به انجام اعمال مغایر قانون آرویکس.
                                </li>
                                <li>
                                    عدم رعایت قانون جرائم رایانه‌ای جمهوری اسلامی ایران (خریدار موظف به کسب اطلاع کامل در مورد این قانون است)
                                </li>
                                <li>
                                    در صورت ارسال اسپم سرویس به مدت ۲۴ و حداکثر یک هفته مسدود خواهد ماند.
                                </li>
                                <li>
                                    انجام فعالیت‌هایی که به هر شکل موجب اختلال در ارائه خدمات آرویکس به خریدار و یا سایر خریداران گردد.
                                </li>
                                <li>
                                    استفاده از سرویس جهت ایجاد اختلال و یا نفوذ در سیستم‌های اشخاص ثالث.
                                </li>
                                <li>
                                    راه‌اندازی mail server Open relay
                                </li>
                                <li>
                                    انجام فعالیت IRC
                                </li>
                                <li>
                                    استفاده از شبکه‌های اشتراک گذاری p٢p
                                </li>
                                <li>
                                    عدم رعایت محدوده استفاده از منابع تخصیص یافته در هر سرویس
                                </li>
                                <li>
                                    مختل کردن امنیت سیستم یا شبکه، در صورت بروز چنین مسائلی آرویکس حق پیگرد قانونی و شکایت از متخلف به مراجع قانونی را محفوظ می‌دارد.
                                </li>
                            </ul>
                            <ul className="list-style-circle pl-2 pr-3">
                                نمونه‌هایی از اختلال در امنیت سیستم یا شبکه شامل موارد ذیل می‌شود:
                                <br />
                                دسترسی یا استفاده غیرمجاز از داده‌ها، سیستم یا شبکه، از جمله هرگونه تلاش برای جستجو، اسکن یا آزمایش آسیب‌پذیری یک سیستم یا شبکه.
                                <br />
                                مداخله در خدمات ارائه شده به هر کاربر، میزبان یا شبکه شامل:
                                <li>
                                    تلاش برای دسترسی به اطلاعات نامه‌های الکترونیکی،
                                </li>
                                <li>
                                    تلاش عامدانه برای پر کردن فضای یک سیستم،
                                </li>
                                <li>
                                    تلاش برای تغییر و یا حذف اطلاعات وب‌سایت‌ها بدون مجوز از مالکان آن‌ها و ...
                                </li>
                            </ul>
                            {/* ------------------------- */}
                            <h2 id="title9"> فصل نهم : محدوده استفاده از منابع CPU , Disk space , Traffic Data transfer , Ram, و ... </h2>
                            <p>
                                ۱ -آرویکس در تعرفهٔ خود یا در سایت این موارد را مشخص نموده و محدوده استفاده از منابع سرویس‌های آرویکس برای عموم سرویس‌ها در تعرفه درج شده است.
                            </p>
                            <p>
                                ۲ -ملاک تشخیص، نرم‌افزارهای کنترلی و واحد فنی آرویکس خواهد بود.
                            </p>
                            <p>
                                ۳ -آرویکس در صورت تجاوز منابع سرویس کاربر از حدود تعیین شده، حق قطع سرویس بدون اخطار قبلی تا تعیین وضعیت را خواهد داشت و مراتب را به اطلاع خریدار خواهد رساند. در این صورت، آرویکس، پس از ارتقا سرویس توسط خریدار و یا کاهش مصرف، اقدام به ارائه سرویس‌دهی خواهد نمود.
                            </p>
                            <p>
                                ۴ -در صورتی که خریدار در استفاده از برخی منابع که قابل‌اندازه‌گیری نیستند، از حد استاندارد سرویس تجاوز نماید به تشخیص بخش فنی، آرویکس حق قطع سرویس تا تعیین تکلیف جدید را دارد.
                            </p>
                            {/* -------------------------- */}
                            <h2 id="title10"> فصل دهم : محتوا </h2>
                            <p>
                                ۱ - سرویس‌های آرویکس می‌بایست در جهت مقاصد قانونی استفاده گردد. انتقال، نگهداری یا انتشار هرگونه اطلاعات یا داده که مخالف هر قانون اجرایی باشد یا مستقیماً به تخلف از قانون خاصی کمک کند ممنوع است. این مورد می‌تواند شامل موارد زیر باشد (البته محدود به موارد زیر نیست)
                            </p>
                            <ul className="list-style-circle pl-2 pr-3">
                                <li>
                                    راه اندازی VPN برروی سرویس‌های دریافت شده از آرویکس
                                </li>
                                <li>
                                    قرار دادن هرگونه داده تحت پوشش قانون کپی‌رایت بدون مجوز
                                </li>
                                <li>
                                    استفاده غیرمجاز از علائم و اسامی تجاری دیگران
                                </li>
                                <li>
                                    استفاده غیرمجاز از لوگوهای دیگران
                                </li>
                                <li>
                                    هرگونه تخلف از قانون جرائم رایانه‌ای ایران
                                </li>
                                <li>
                                    اهانت به هر شخص، گروه، سازمان، قوم یا کشور
                                </li>
                                <li>
                                    انتشار اکاذیب علیه دیگران
                                </li>
                                <li>
                                    کلاه‌برداری‌های اینترنتی و هرگونه عاملی که موجب فریب بازدیدکنندگان شود.
                                </li>
                                <li>
                                    سایت‌های فعال در زمینه HYIP و یا دارای سیستم هرمی
                                </li>
                                <li>
                                    فروش یا تبلیغ هرگونه کالا یا خدمات غیرمجاز طبق قوانین جاری ایران و کشوری که سرورها در آن قرار دارد
                                </li>
                                <li>
                                    سایت‌های مرتبط یا حامی گروه‌های شناخته شده تروریست بین‌المللی
                                </li>
                                <li>
                                    استفاده از سرویس‌های آرویکس برای نگهداری، ارسال، نمایش، انتقال، تبلیغ یا هر عمل مشابه بر روی داده‌های مرتبط با مسائل جنسی و غیراخلاقی به هر شکل
                                </li>
                            </ul>
                            <p>
                                ۲ - مسئولیت محتوای درج شده در سرویس تأمین شدهٔ خریدار و یا متصل به دامنه خریداری‌شده از آرویکس تماماً بر عهده خریدار است.
                            </p>
                            <p>
                                ۳ - در صورت عدم رعایت قانون جرائم رایانه‌ای جبران کلیه خسارات و هزینه‌های مادی و معنوی کلی و جزئی وارد بر آرویکس در پاسخگویی به مراجع ذیصلاح بر عهده خریدار خواهد بود.
                            </p>
                            <p>
                                ۴ - آرویکس بر طبق قانون موظف است در صورت برخورد با موارد مجرمانه نسبت به انسداد سرویس‌ها و اقامه دعوا و ارائه اطلاعات خریدار که محتوای مجرمانه در سرویس خود و یا در سرویس متصل به دامنه خود را دارد به مراجع قضائی اقدام نماید.
                            </p>
                            {/* ----------------------- */}
                            <h2 id="title11"> فصل يازدهم : مسئوليت خريدار </h2>
                            <p>
                                ۱ - خریدار موظف است که کلیه مواد توافقنامه، ضوابط آرویکس و قوانین جمهوری اسلامی ایران را رعایت نماید. درصورت عدم رعایت، آرویکس حق قطع سرویس به‌صورت موقت یا دائم و پیگرد قانونی و دریافت خسارت از خریدار را دارد و خریدار موظف به جبران کلیه خسارات و هزینه وارده بر آرویکس است. همچنین هیچ‌وجهی بابت فسخ سرویس و یا ایام باقیمانده سرویس یا خسارت وارده به خریدار متخلف، پرداخت نخواهد شد.
                            </p>
                            <p>
                                ۲ - خریداران مسئول عملکرد خود در رابطه با سرویس تهیه شده می‌باشند همچنین می‌بایست به حقوق سایر خریداران آرویکس احترام بگذارند. آرویکس حق تعلیق یا قطع دائم سرویس خریداران که به‌صورت خواسته یا ناخواسته موجب اختلال در سرویس سایر خریداران می‌گردد را برای خود محفوظ می‌دارد. ضمناً خریدار حق هیچ‌گونه اعتراضی را نخواهد داشت.
                            </p>
                            <p>
                                ٣ - به غیر از آن دسته از قوانین که مستقیماً به نحوه استفاده از سرویس برمی‌گردد که با پایان مدت زمان سرویس به پایان می‌رسد. بندهای دیگر قوانین پابرجا بوده و خریدار همواره موظف به رعایت قوانین عمومی و حقوقی آرویکس خواهد بود.
                            </p>
                            <p>
                                ۴ - کلیه مسئولیت‌های عدم استفاده صحیح از سرویس خریداری‌شده با خریدار است و آرویکس تنها مسئول ارائه سرویس استاندارد و بر اساس تعهدات فنی اعلام شده در بسته‌های میزبانی خود است.
                            </p>
                            {/* ------------------------- */}
                            <h2 id="title12"> فصل دوازدهم : گارانتي و پشتيباني </h2>
                            <p>
                                ١ - آرویکس تنها مسئولیت سرویس‌دهی در حوزه خرید را دارد. در خصوص اختلالات و موارد دیگر مانند ارتباط اینترنت خریدار و برنامه‌نویسی وب و نرم‌افزارها و مشکل دامنه‌ای که نزد دیگران ثبت شده است مسئولیتی متوجه آرویکس نیست.
                            </p>
                            <p>
                                ٢ - ملاک وجود اختلال در سرویس، گزارش واحد فنی و یک سایت Tracer معتبر که باید توسط آرویکس تائید شده باشد، است.
                            </p>
                            <p>
                                ٣ - زمان اختلال در سرویس خریدار در صورت درخواست کتبی وی با اعلام مدت اختلال و تائید واحد فنی درصورتی‌که میزان تجمعی آن از ابتدای دوره از ۰.۲% کل دوره بیشتر باشد به مدت سرویس افزوده می‌گردد.
                            </p>
                            <p>
                                ٤ - ارائه خدمات پشتیبانی در آرویکس به‌صورت ۲۴ ساعته و هفت روز هفته است. ارائه خدمات در سایر واحدها، در روزهای کاری، ساعات ٨ الی ۱۷ انجام می‌گیرد.
                            </p>
                            <p>
                                ٥ - نحوه ارتباط مستند خریدار برای درخواست‌های گارانتی و پشتیبانی، باید از طریق ارسال ایمیل یا تیکت از حساب کاربری باشد، تا زمان دقیق ارسال درخواست، زمان دقیق رسیدگی و هویت خریدار مشخص باشد.
                            </p>
                            <p>
                                ٦ - سرویس‌ها و رویه عمومی آرویکس، بر اساس استاندارد فنی بین‌المللی و مصالح آرویکس تعریف شده و برای خریدار خاص غیر قابل تفکیک و تغییر است و خریدار موظف است بررسی لازم خود را قبل از خرید سرویس به عمل آورده باشد.
                            </p>
                            <p>
                                ٧ - آرویکس مسئولیتی در قبال عدم تطابق سرویس خریداری‌شده با نیاز مشتری ندارد.
                            </p>
                            <p>
                                ٨ - آرویکس نسبت به نصب و عملیاتی شدن نرم‌افزارهایی را که مورد تائید بخش فنی نیست مسئولیتی ندارد.
                            </p>
                            <p>
                                ٩ - میزان مسئولیت آرویکس در مقابل حوادث ناخواسته و خسارات غیرمستقیم وارد بر خریدار در استفاده از سرویس و یا اطلاعات خریدار محدود به بند خسارات این قانون خواهد بود.
                            </p>
                            <p>
                                ١٠ - حداکثر زمان پاسخگویی به تیکت‌ها و درخواست‌های پشتیبانی ۲۴ ساعت پس از ثبت آن است. درخواست‌ها و مشکلاتی که نیاز به بررسی بیشتر و پیگیری داشته باشند حداکثر طی ۷ روز پاسخ داده خواهند شد.
                            </p>
                            {/* --------------------------- */}
                            <h2 id="title13"> فصل سیزدهم : ضوابط خاص ميزباني </h2>
                            <p>
                                ١ - آرویکس مسئولیتی در قبال تحویل داده‌های موجود در فضای میزبانی وب به خریدار نداشته و خریدار موظف به استفاده از FTP خواهد بود.
                            </p>
                            <p>
                                ٢ - آرویکس مسئولیتی نسبت به بارگذاری اطلاعات (Upload) در فضای میزبانی خریدار و یا download اطلاعات وی به‌صورت رایگان ندارد.
                            </p>
                            <p>
                                ٣ – آرویکس، مسئولیتی نسبت به نصب نرم‌افزار برای خریدار به‌صورت رایگان ندارد.
                            </p>
                            <p>
                                ٤ - آرویکس تلاش خود را جهت راهنمایی خریدار در مسائل فنی می‌کند، ولی خریدار ، مسئول است با اطلاعات فنی سرویس میزبانی وب آشنایی داشته و آرویکس مسئولیتی نسبت به آموزش نحوه بهره‌برداری صحیح از سرویس ندارد.
                            </p>
                            {/* ---------------------------- */}
                            <h2 id="title14"> فصل چهاردهم : ساير ضوابط خاص </h2>
                            <p>
                                ١ - خریدار موظف است سرویس‌هایی که نزد آرویکس تمدید می‌نماید را از نظر تمدید شدن کنترل نموده و در صورت عدم اعمال مراتب تمدید توسط آرویکس، حداکثر تا ۷ روز تقویمی پس از زمان انقضاء مراتب را کتباً به آرویکس اعلام نماید.
                            </p>
                            <p>
                                ٢ - توافقات خاص تنها به‌صورت کتبی و با مهر و امضاء مجاز آرویکس معتبر خواهد بود و توافقات با پرسنل به‌صورت کتبی یا شفاهی و یا به هر شکل دیگر از درجه اعتبار ساقط است.
                            </p>
                            <p>
                                ٣ - خریدار موظف است تحویل، تطابق و اجرای صحیح سرویس خریداری‌شده را کنترل نماید.
                            </p>
                            <p>
                                ٤ - آرویکس، هیچ مسئولیتی برای به دست آوردن دامنه از دست رفته و یا اطلاعات حذف شده از روی سرورهای میزبانی پس از اتمام قرارداد نخواهد داشت.
                            </p>
                            <p>
                                ٥ - مسئولیت اطلاع از زمان انقضاء و کنترل سرویس بر عهده خریدار است لیکن آرویکس، انقضاء سرویس‌های خریدار را از طریق پست الکترونیک به وی یادآوری می‌نماید و عدم دسترسی خریدار به پست الکترونیکی خود، مسئولیتی را برای آرویکس ایجاد نخواهد کرد.
                            </p>
                            <p>
                                ٦ - وضعیت اعتبار، فهرست سرویس‌ها و پرداخت‌های خریدار در حساب کاربری وی قرار دارد و همواره وی می‌تواند برای آگاهی از وضعیت عملکرد خود از با آدرس ایمیل و کلمه عبور خود به آن مراجعه نماید.
                            </p>
                            <p>
                                ٧ - مدت زمان قطع سرویس ناشی از عدم پرداخت مطالبات آرویکس، بررسی جرائم و اعمال تشخیص کارشناسی به مدت زمان سرویس‌دهی افزوده نمی‌گردد.
                            </p>
                            <p>
                                ٨ - امکان دریافت هرگونه سرویس هدیه و یا کمک‌هزینه‌های پیش‌بینی‌شده در برخی از سرویس‌ها، تنها در هنگام خرید بسته وجود دارد و طی مدت قرارداد امکان رجوع به آن وجود نداشته و در صورت عدم استفاده هزینه آن به حساب کاربری خریدار واریز نمی‌شود.
                            </p>
                            {/* --------------------------- */}
                            <h2 id="title15"> فصل پانزدهم : نسخه پشتيبان (Backup) </h2>
                            <p>
                                ١ - مسئولیت تهیه نسخه پشتیبان از داده‌ها و فایل‌ها با خریدار بوده و خریدار می‌بایست پشتیبان کلیه داده‌های مورد نیاز خود را در جایی غیر از تجهیزات و سرورهای آرویکس نگهداری نمایند. در صورت بروز هر مشکل و عدم توانایی بازگرداندن اطلاعات از آرشیو بکاپ هیچ‌گونه اعتراضی مسموع نخواهد بود و آرویکس در این زمینه هیچ‌گونه مسئولیتی ندارد.
                            </p>
                            <p>
                                ٢ - مسئولیت بکاپ گرفتن در سرویس‌ها صرفاً به عهده خود خریدار است و آرویکس در این زمینه هیچ‌گونه مسئولیتی ندارد.
                            </p>
                            {/* --------------------------- */}
                            <h2 id="title16"> فصل شانزدهم : شرايط و قوانين مالي </h2>
                            <p>
                                ١ - نحوه پرداخت سرویس‌ها، به صورت آنلاین و از درگاه پرداخت متصل به سایت است. در صورتی که از روش‌های دیگر مانند کارت به کارت واریز شود، خریدار باید اطلاعات پرداختی را به آرویکس اطلاع دهد. به طور عمومی خریدار موظف است مدارک پرداخت خود را تا پایان مدت سرویس نگهداری نماید.
                            </p>
                            <p>
                                ٢- خریدار موظف است پرداخت خود را به طور نقدی و یا در وجه حساب‌های اعلام شده در سایت آرویکس بپردازد. در شرایطی که آرویکس در راستای مساعدت با خریدار اقدام به پذیرش چک مدت‌دار در خصوص فاکتور وی نماید، با عدم وصول وجه چک، خریدار موظف است در روز سررسید، فوراً اقدام به واریز وجه به حساب‌های اعلام شده نماید و لاشه چک خود را دریافت نماید در غیر این صورت هزینه تأخیر در وصول اسناد، پیگیری‌های حقوقی، ایاب و ذهاب و سایر موارد نیز بر عهده خریدار خواهد بود؛ ضمناً در این‌گونه موارد آرویکس حق دارد ضمن تعلیق سرویس، فاکتور خریداری که قسمتی از وجه آن در زمان مورد توافق وصول نشده را باطل نموده و خسارات وارده بر آرویکس و هزینه سرویس را از آن کسر نماید.
                            </p>
                            <p>
                                ٣ - به دلیل محدودیت‌های نگهداری داده‌های حسابداری، فیش بانکی و یا حواله‌ای که برای افزایش اعتبار و یا پرداخت یک صورت حساب، تنها تا ۶ ماه پس از واریز قابلیت استفاده را خواهد داشت.
                            </p>
                            <p>
                                ٤ - آرویکس، حق تأمین و وصول کلیه مطالبات و جرائم، سود تأخیر در پرداخت و سایر هزینه‌های خود، از محل قطع موقت و یا دائم کلیه سرویس‌ها و اعتبارات خریدار نزد خود را خواهد داشت و در صورتی که خریدار فوراً به اخطار اعلام شده از سوی آرویکس توجه ننماید، حق هرگونه اعتراض بعدی را از خود سلب می‌نماید؛ لازم به ذکر است مدت قطعی سرویس ناشی از عدم پرداخت مطالبات آرویکس به مدت سرویس‌دهی افزوده نخواهد شد و کلیه مسئولیت‌های ناشی از این نوع قطع سرویس بر عهده خریدار است.
                            </p>
                            <p>
                                ٥ - در صورتی که آرویکس تشخیص دهد هر یک از سرویس‌ها و یا کالای خریداری‌شده خریدار در زمان تحویل، با حجم بیشتر و یا با امکانات بهتر و یا زمان طولانی‌تر و یا هر مورد هزینه‌بر بالاتری از مشخصات مندرج در فاکتور مورد استفاده وی قرار گرفته و یا به هر دلیل خریدار به این حجم بیشتر و یا امکانات بهتر و منابع بیشتر و یا زمان طولانی‌تر دست پیدا کرده است، حق خواهد داشت مبلغ مابه‌التفاوت تعرفه‌ای آن را از خریدار دریافت نماید؛ پرداخت این هزینه برای خریدار اجباری است و آرویکس حق استیفا طلب خود را از نحوه دریافت بدهی‌های خریدار خواهد داشت.
                            </p>
                            <p>
                                ٦ - آرویکس برای گسترش خدمات و افزایش سرویس‌دهی به خریداران خود در بازه‌های مختلف زمانی اقدام به انتشار و توزیع کدهای تخفیف می‌نماید. لازم به ذکر است که آرویکس در پذیرش و یا عدم پذیرش این کد‌ها در بازه‌های تخفیف و ایام خاص مختار است.
                            </p>
                            <p>
                                ٧ - برای خرید یک سرویس میزبانی و یا یک بسته نرم‌افزاری امکان پذیرش بیش از یک کد تخفیف وجود ندارد.
                            </p>
                            <p>
                                ٨ - هیچ‌یک از کمک‌هزینه‌ها و خدمات مازاد بسته‌ها قابل تبدیل به ارزش ریالی و کسر از فاکتور نیست.
                            </p>
                            <p>
                                ٩ - بازگشت پول:
                                <br />
                                ضمانت بازگشت وجه تنها در ٣ روز اول سرویس ‌های جدید ارائه می‌شود و تمدید و ارتقای هر سرویس شامل ضمانت برگشت وجه نخواهد شد.
                                <br />
                                در صورتی که مشکل اعلام شده توسط خریدار، مربوط به سرویس دهنده بوده و توسط پشتیبانی قابل حل نباشد، آرویکس هزینه خدمات را به خریدار، برگشت خواهد داد.
                            </p>
                            <p>
                                ١٠ - بدهی‌ها:
                                <br />
                                آرویکس با اعلام و اخطار قبلی در خصوص تسویه بدهی‌های معوق، حق قطع سرویسی که خریدار برای آن بدهکار است و یا سایر سرویس‌ها و خریدهای قبلی خریدار را داشته و می‌تواند در صورت تمایل با تملک برخی از آن‌ها معادل مبلغ آن در فاکتور فروش (نسبت به مدت اعتبار آن به کل زمان سرویس) از میزان بدهی (اصل بدهی، جرائم و یا خسارات وارده بر آرویکس) کسر نماید.
                                <br />
                                آرویکس، برای هر روز تاخیر خریدار در تسویه بدهی‌ها، خسارات و یا جرائم و امثالهم معادل پنج دهم درصد خسارت به صورت روزانه منظور می‌نماید که به طور یکجا و در هنگام تسویه بدهی، کاربر موظف به تسویه مجموع آن خواهد بود.
                            </p>
                            <p>
                                ١١ - جرائم:
                                <br />
                                آرویکس حق دریافت هزینه برای تأخیر مشتری در تمدید به موقع سرویس‌های دامنه میزبانی و امثالهم را خواهد داشت.
                                <br />
                                آرویکس حق دریافت هزینه فنی، جهت رفع مسدودی یک سرویس را خواهد داشت.
                                <br />
                                مدت زمان اعتبار قیمت ارائه شده برروی پیش‌فاکتور، فاکتور و صورت‌حساب‌ها نیز ٥ روز از تاریخ صدور خواهد بود.
                            </p>
                            {/* ------------------------------- */}
                            <h2 id="title17"> فصل هفدهم : فورس ماژور </h2>
                            <p>
                                آرویکس هیچ‌گونه مسئولیتی در برابر انجام ندادن تعهدات خود در هنگام موارد فورس ماژور، مانند بلایای طبیعی، جنگ، شورش، آشوب اجتماعی، انفجار، اعتصاب، محدودیت‌های دولتی و تحریم را ندارد. با این حال در صورت بروز چنین مواردی آرویکس تلاش خود را برای کاهش اثرات این موارد می‌نماید. بروز هر یک از موارد فورس ماژور موجب تخطی خریداران از شرایط خدمات نخواهد شد. تحریم و عدم امکان تبادل ارز از موارد فورس ماژور خواهد بود.
                            </p>
                            {/* ------------------------------- */}
                            <h2 id="title18"> فصل هجدهم : انقضاء و تمديد خدمات </h2>
                            <p>
                                تاریخ تمدید سرویس منقضی شده، از زمان تاریخ انقضاء قبلی خواهد بود همچنین آرویکس مسئولیتی در قبال نگهداری و ارائه اطلاعات و داده‌های خریدار، پس از تاریخ انقضا نخواهد داشت.
                            </p>
                            {/* ------------------------------- */}
                            <h2 id="title19"> فصل نوزدهم : پذيرش مواد توافقنامه و ضوابط </h2>
                            <p>
                                با پرداخت مبلغ فاکتور و یا عضویت در سایت آرویکس، خریدار مواد توافقنامه و قوانین آرویکس را پذیرفته و نیازی به اخذ امضاء از خریدار وجود ندارد.
                            </p>
                            {/* ------------------------------- */}
                            <h2 id="title20"> فصل بیستم : رویه حفاظت از اطلاعات </h2>
                            <p>
                                آرویکس در قبال حفاظت کامل از اطلاعات شخصی خریدار مسئول بوده و حق استفاده و ارائه به اشخاص ثالث و یا استفاده تبلیغاتی را ندارد و تنها در صورت دریافت حکم قضائی، اطلاعات شخص مورد تقاضا را بنا بر ضرورت قانونی در اختیار مقام قضائی قرار خواهد داد. اطلاعات مندرج در Whois دامنه به دلیل قابل رؤیت بودن برای عموم از این قاعده مستثنا است.
                            </p>
                            {/* ------------------------------- */}
                            <h2 id="title21"> فصل بیست و یک : خسارت و رفع مسئوليت </h2>
                            <p>
                                ١ - خدمات آرویکس به همان شکلی که هستند ارائه می‌شوند و هیچ‌گونه گارانتی صریح و ضمنی شامل آن‌ها نمی‌گردد. ضمناً هیچ‌گونه ضمانتی مانند مطابق نیاز خریدار بودن، بی‌عیب بودن، عدم قطعی، امنیت و ... ارائه نمی‌شود. همه ضمانت‌های کتبی و شفاهی در مورد سرویس‌های آرویکس که توسط کارکنان یا نمایندگان و یا سایرین ارائه شوند فاقد اعتبار می‌باشند. آرویکس هیچ‌گونه خسارتی بابت عملکرد سرویس خود به هر نحو نمی‌پردازد. آرویکس تحت هیچ شرایطی مسئول حوادث احتمالی (مانند از دست رفتن داده‌ها روی سرور) نخواهد بود.
                            </p>
                            <p>
                                ٢- سقف خسارات اثبات شده توسط مرجع رفع اختلاف به خریدار برای هریک از انواع سرویس‌ها، حداکثر معادل هزینه پرداختی آن سرویس توسط خریدار بوده و آرویکس هیچ‌گونه مسئولیتی در قبال جبران خساراتی مازاد بر این مبلغ نخواهد داشت
                            </p>
                            {/* ------------------------------- */}
                            <h2 id="title22"> فصل بیست و دوم : سلب اختيار </h2>
                            <p>
                                عدم اجرای بخشی از ضوابط و مواد توافقنامه آرویکس نسبت به خریدار یا دیگران به منزله سلب حق اجرای مواد توافقنامه از آرویکس نیست.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer */}
            <Footer />
        </div>
    )
}

export default TermsPage