import React from 'react'
import FlagSVG from './svgs/flagSVG'
import MobileSVG from './svgs/mobileSVG'

const WhyArvix = () => {
    return (
        <div>
            <div className="row mb-3 text-right">
                <h3 className="w-100 header-title-row text-center"> چرا باید آرویکس را انتخاب کنید؟ </h3>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4 mb-4 description-text-sec">
                    <h4> کاهش هزینه ها </h4>
                    <p className="">
                        محاسبه هزینه ها در پنل کاربری آرویکس برای اکثر سرویس ها به صورت ساعتی بوده و شما بدون نیاز به پرداخت های ماهانه قادر به استفاده از سرور ها بر اساس میزان استفاده خود خواهد بود. این مسئله نه تنها باعث کاهش هزینه ها و عدم نیاز به پرداخت های اضافه خواهد شد بلکه امکان تغییر سرور و مشخصات آن را در هر لحظه بدون نیاز به هیچگونه پیش پرداخت یا هزینه اضافه ای را فراهم می کند.
                    </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 sec-svg mt-4 mb-4">
                    <FlagSVG />
                </div>
            </div>
            <div className="row mb-3 text-right">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4 mb-4 description-text-sec">
                    <h4> پنل پیشرفته و خودکار </h4>
                    <p className="">
                    پنل مدیریت سرور های آرویکس یکی از پیشرفته ترین پنل ها در نوع خود بوده به طوری که تمامی امکانات لازم برای مدیریت سرور را بدون نیاز به دخالت بخش پشتیبانی در اختیار شما قرار خواهد داد. همچنین اطلاعات دقیق سرور نیز در اختیار شما خواهد بود و در هر لحظه قادر خواهید بود تغییرات لازم چه در سخت افزار سرور و چه در نرم افزار سرور را از طریق پنل اعمال کنید.
                    </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 sec-svg mt-4 mb-4">
                    <MobileSVG />
                </div>
            </div>
        </div>

    )
}

export default WhyArvix