import React from 'react'

const FacebookSVG = () => {
    return (
        <svg viewBox="0 0 21 37" className="SVG-facebook">
            <g id="Layer_2" data-name="Layer 2">
                <g id="_5-footer" data-name="5-footer">
                    <path className="cls-1" d="M13,37H7.05a1.8,1.8,0,0,1-1.8-1.79V21.84H1.79A1.79,1.79,0,0,1,0,20.05V14.32a1.8,1.8,0,0,1,1.79-1.8H5.25V9.66a9.75,9.75,0,0,1,2.58-7A9.31,9.31,0,0,1,14.69,0h4.52A1.79,1.79,0,0,1,21,1.8V7.12a1.79,1.79,0,0,1-1.79,1.79H16.16c-.92,0-1.16.18-1.21.24s-.18.36-.18,1.1v2.26H19a1.84,1.84,0,0,1,.89.23,1.8,1.8,0,0,1,.92,1.57v5.73A1.8,1.8,0,0,1,19,21.84H14.77V35.21A1.8,1.8,0,0,1,13,37ZM7.42,34.83H12.6v-14a1.2,1.2,0,0,1,1.2-1.2h4.82v-5H13.8a1.19,1.19,0,0,1-1.2-1.19V10.26a3.7,3.7,0,0,1,.72-2.54,3.7,3.7,0,0,1,2.84-1h2.67V2.18H14.69C10.21,2.17,7.42,5,7.42,9.66V13.5a1.19,1.19,0,0,1-1.2,1.19h-4v5H6.22a1.2,1.2,0,0,1,1.2,1.2ZM19.2,2.18Z" />
                </g>
            </g>
        </svg>
    )
}

export default FacebookSVG