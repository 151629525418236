import React from 'react';

const LogoSVG = () => {
    return (
        <svg viewBox="0 0 98.02 58.71" className="SVG-logoe">
            <title>Arvix Logo</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="_1" data-name="1">
                    <path className="cls-1" d="M56.81,36.4H31.43A10.54,10.54,0,0,1,20.89,25.86h0A10.54,10.54,0,0,1,31.43,15.31h0a8,8,0,0,1,8-7.33,8.14,8.14,0,0,1,1.92.23A18.48,18.48,0,0,1,57,0" />
                    <rect className="cls-2" x="31.92" y="29.42" width="5.49" height="7.24" />
                    <path className="cls-2" d="M31.92,24.43V17.19h5.49v7.24H47.92c0-2.41,0-4.83,0-7.24H37.41V11.7H47.92v5.49h5.45v7.24H47.92v5h5.45v7.24H47.88V29.42H37.41v-5Z" /><text className="cls-3" transform="translate(55.67 54.93)">Arvix <tspan className="cls-4" x="47.65" y="0">Cloud</tspan></text>
                    <rect className="cls-1" x="64.64" y="16.47" width="3.99" height="3.99" transform="translate(133.27 36.92) rotate(-180)" />
                    <rect className="cls-1" x="64.64" y="8.4" width="3.99" height="3.99" transform="translate(133.27 20.8) rotate(-180)" />
                    <rect className="cls-1" x="72.88" y="24.53" width="3.99" height="3.99" transform="translate(149.74 53.04) rotate(-180)" />
                    <rect className="cls-1" x="60.66" y="4.42" width="3.99" height="3.99" transform="translate(125.3 12.82) rotate(-180)" />
                    <rect className="cls-1" x="76.36" y="4.42" width="3.99" height="3.99" transform="translate(156.71 12.82) rotate(-180)" />
                    <rect className="cls-1" x="72.36" y="0.69" width="3.99" height="3.99" transform="translate(148.71 5.36) rotate(-180)" />
                    <rect className="cls-1" x="64.64" y="0.43" width="3.99" height="3.99" transform="translate(133.27 4.85) rotate(-180)" />
                    <rect className="cls-1" x="80.85" y="12.48" width="3.99" height="3.99" transform="translate(165.69 28.94) rotate(-180)" />
                    <rect className="cls-1" x="72.36" y="12.48" width="3.99" height="3.99" transform="translate(148.71 28.94) rotate(-180)" />
                    <rect className="cls-1" x="56.76" y="4.42" width="4.15" height="3.99" transform="translate(117.67 12.82) rotate(-180)" />
                    <rect className="cls-1" x="56.66" y="20.45" width="4.25" height="3.99" transform="translate(117.57 44.89) rotate(-180)" />
                    <rect className="cls-1" x="76.86" y="20.45" width="3.99" height="3.99" transform="translate(157.71 44.89) rotate(-180)" />
                    <rect className="cls-1" x="68.76" y="20.45" width="3.99" height="3.99" transform="translate(141.51 44.89) rotate(-180)" />
                    <rect className="cls-1" x="60.72" y="12.39" width="3.92" height="4.07" transform="translate(125.36 28.86) rotate(-180)" />
                    <rect className="cls-1" x="64.64" y="24.44" width="3.99" height="3.99" transform="translate(133.27 52.87) rotate(-180)" />
                    <rect className="cls-1" x="80.85" y="32.42" width="3.99" height="3.99" transform="translate(165.69 68.82) rotate(-180)" />
                    <rect className="cls-1" x="60.66" y="28.43" width="3.99" height="3.99" transform="translate(125.3 60.84) rotate(-180)" />
                    <rect className="cls-1" x="72.66" y="32.42" width="3.99" height="3.99" transform="translate(149.31 68.82) rotate(-180)" />
                    <rect className="cls-1" x="64.64" y="32.42" width="3.99" height="3.99" transform="translate(133.27 68.82) rotate(-180)" />
                    <rect className="cls-1" x="56.67" y="32.42" width="3.99" height="3.99" transform="translate(117.32 68.82) rotate(-180)" />
                </g>
            </g>
        </svg>
    )
}

export default LogoSVG