import React from 'react'
import LogoSVG from './svgs/logoSVG'
import InstagramSVG from './svgs/instagramSVG'
import LinkedInSVG from './svgs/linkedInSVG'
import FacebookSVG from './svgs/facebookSVG'
import TelegramSVG from './svgs/telegramSVG'

const Footer = () => {
    return (
        <footer>
            <div className="row">
                {/* logo and descrption */}
                <div className="col-lg-4 col-md-12 col-md-6 col-sm-12 logo-and-description">
                    <LogoSVG />
                    <p>
                        تمامی سرویس ها به صورت خودکار و تنها با پذیرش قوانین در اختیار کاربران قرار میگیرد. <br />
                        مسئولیت استفاده از تمامی سرویس های تهیه شده بر عهده خریدار میباشد. <br />
                        تمام حقوق مادی و معنوی این سایت متعلق به آرویکس استودیو میباشد. <br />
                        شماره تماس: ۰۹۳۵۱۱۲۰۱۶۵ <br />
                        Arvix.Studio 2019 ©
                    </p>
                </div>
                {/* design by arvix */}
                <div className="col-lg-4 col-md-12 col-md-6 col-sm-12 design-by-arvix" style={{ direction: "ltr" }}>
                    <p>
                        An <a href="https://arvix.studio"><strong>Arvix.Studio</strong></a> Product

                    </p>
                </div>
                {/* social link */}
                <div className="col-lg-4 col-md-12 col-md-6 col-sm-12 text-left social-link">
                    <div className="position-social">
                        <a href="/">
                            <LinkedInSVG />
                        </a>
                        <a href="/">
                            <InstagramSVG />
                        </a>
                        <a href="/">
                            <FacebookSVG />
                        </a>
                        <a href="/">
                            <TelegramSVG />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer