import React from 'react'

const LinkedInSVG = () => {
    return (
        <svg viewBox="0 0 25.83 29" className="SVG-linked">
            <g id="Layer_2" data-name="Layer 2">
                <g id="_5-footer" data-name="5-footer">
                    <path className="cls-1" d="M5.91,8.59H1a1,1,0,0,0-1,1V28a1,1,0,0,0,1,1H5.91a1,1,0,0,0,1-1V9.58A1,1,0,0,0,5.91,8.59ZM5.2,27.3H1.73v-17H5.2Z" />
                    <path className="cls-1" d="M3.47,0A3.47,3.47,0,1,0,6.93,3.47,3.47,3.47,0,0,0,3.47,0Zm0,5.23A1.77,1.77,0,1,1,5.23,3.47,1.76,1.76,0,0,1,3.47,5.23Z" />
                    <path className="cls-1" d="M22.72,10.75a6.33,6.33,0,0,0-4.19-1.47,6.48,6.48,0,0,0-2.71.66v-.4a1,1,0,0,0-1-.95h-5a1,1,0,0,0-1,.95V28.05a1,1,0,0,0,1,.95H15a.85.85,0,0,0,.85-.85v-11a1.76,1.76,0,0,1,1.68-1.77,1.74,1.74,0,0,1,1.79,1.73v11.1a.83.83,0,0,0,.83.83H25a.83.83,0,0,0,.83-.83V17.41A8.64,8.64,0,0,0,22.72,10.75ZM24.13,27.3H21V17.07a3.44,3.44,0,0,0-6.87,0V27.3H10.64v-17h3.48v1.24h0a.84.84,0,0,0,1.41.63,4.73,4.73,0,0,1,3-1.18,4.68,4.68,0,0,1,3.06,1.07,7,7,0,0,1,2.5,5.36Z" />
                </g>
            </g>
        </svg>
    )
}

export default LinkedInSVG