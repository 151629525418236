import React from 'react';

const LaptopSVG = () => {
        return (
            <svg viewBox="0 0 703.37 546.99" className="SVG-laptop">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="_1" data-name="1">
                        <path className="cls-1" d="M420.05,29.81c0-.44.05-.88.05-1.33a17.21,17.21,0,0,0-27.66-13.67A22.84,22.84,0,0,0,352,10.26a17.71,17.71,0,0,0-21.77,22.23h-.42a15.54,15.54,0,0,0-15.65,15.8,15.56,15.56,0,0,0,17.26,14.64l17.26-.18h68.54A15.69,15.69,0,0,0,432.9,47.06V45.24A15.7,15.7,0,0,0,420.05,29.81Z" />
                        <path className="cls-2" d="M689,116.05c0-.49.06-1,.06-1.49a19.27,19.27,0,0,0-31-15.32,25.59,25.59,0,0,0-45.31-5.09,19.84,19.84,0,0,0-24.38,24.9h-.48a17.12,17.12,0,1,0,1.81,34.09l19.33-.2H685.8a17.57,17.57,0,0,0,17.57-17.57v-2.05A17.57,17.57,0,0,0,689,116.05Z" />
                        <path className="cls-2" d="M151.28,116.4c0-.43.06-.87.06-1.31A17,17,0,0,0,124,101.57a22.59,22.59,0,0,0-42-.79,22.6,22.6,0,0,0-42,.79,17,17,0,0,0-27.35,13.52c0,.44,0,.88.06,1.31A15.53,15.53,0,0,0,0,131.66v1.81A15.51,15.51,0,0,0,15.51,149h133A15.5,15.5,0,0,0,164,133.47v-1.81A15.53,15.53,0,0,0,151.28,116.4Z" />
                        <rect className="cls-3" x="212.99" y="306.59" width="296.52" height="134.05" rx="12.5" />
                        <path className="cls-4" d="M137.37,536.93c14.14,2.55,29.67,4.72,46.44,6.16,16,1.37,30.91,1.89,44.69,1.86Q248.29,466,268.08,387.12a72.27,72.27,0,0,0,.57-22.06,73.18,73.18,0,0,0-10.47-29.26,296.83,296.83,0,0,0-40.4,35.83C163,430,144.44,497.45,137.37,536.93Z" />
                        <path className="cls-4" d="M581.37,539.07a199.29,199.29,0,0,1-88.4,5.2Q469.36,464,445.74,383.7l19.57-47.23a353.88,353.88,0,0,1,43.85,45.88C554.85,439.69,573.27,499.6,581.37,539.07Z" />
                        <rect className="cls-5" x="481.34" y="407.69" width="50.19" height="12.84" rx="2.75" transform="translate(-70 104.77) rotate(-11.05)" />
                        <rect className="cls-5" x="490.08" y="429.03" width="52.87" height="12.84" rx="2.75" transform="translate(-73.9 107.1) rotate(-11.05)" />
                        <rect className="cls-5" x="184.8" y="407.61" width="51.06" height="12.84" rx="2.75" transform="matrix(-0.98, -0.19, 0.19, -0.98, 337.39, 860.71)" />
                        <rect className="cls-5" x="175.46" y="427.09" width="56.46" height="12.84" rx="2.75" transform="translate(320.48 898.04) rotate(-168.95)" />
                        <path className="cls-6" d="M526.21,481.86c.24-.46,19.83,4.76,35.69,21.42,15.4,16.17,19.77,35.25,19.29,35.47s-5.33-17.55-21.25-34.12C543.81,487.84,526,482.31,526.21,481.86Z" />
                        <path className="cls-6" d="M192.39,479.59c-.24-.47-19.83,4.75-35.69,21.41-15.4,16.18-19.77,35.25-19.3,35.48s5.34-17.55,21.25-34.12C174.78,485.56,192.62,480,192.39,479.59Z" />
                        <path className="cls-7" d="M459.58,501.54l-198.47-3-2.52-160.23,207.12.67Q462.65,420.27,459.58,501.54Z" />
                        <path className="cls-6" d="M493,544.27,228.5,545q-2.16-71.07-4.31-142.15a13.26,13.26,0,0,1,13.29-13.67l246,.61a13.26,13.26,0,0,1,13.23,13.61Z" />
                        <path className="cls-6" d="M497.29,269.19c-.76-7-1.38-14.41-1.79-22.14-.51-9.45-.65-18.45-.53-26.93a27.16,27.16,0,0,1,2.32,49.07Z" />
                        <path className="cls-6" d="M233.39,269.19c.77-7,1.39-14.41,1.8-22.14.51-9.45.65-18.45.53-26.93a27.16,27.16,0,0,0-2.33,49.07Z" />
                        <path className="cls-7" d="M494.93,219.06c20,65.64-24,119.89-27.56,124.09l-210.71-3.72c-29.1-40.35-33.91-93.19-12.47-137.22,23.86-49,74.17-74.66,122-74.51C420.87,127.88,477.52,161.78,494.93,219.06Z" />
                        <path className="cls-5" d="M471.3,252.69c-3.84,51.06-52.46,88.15-100.62,90.88-52.88,3-109.41-35.23-113.6-90.88C252.74,195,306.64,145.58,364.19,145.58,422.1,145.57,475.6,195.62,471.3,252.69Z" />
                        <circle className="cls-7" cx="364.19" cy="468.9" r="19.19" />
                        <path className="cls-8" d="M425.65,165.09c30.25,20.1,48.15,53.62,45.65,87.6C468,297.26,430.17,336,380,342c40.24-11.46,70.31-44.35,76.92-84.19C465.58,205.38,429.12,168.49,425.65,165.09Z" />
                        <path className="cls-6" d="M510.28,343q.09,8.45.17,16.87c39-12.88,59.11-51,50.17-81.45-6.22-21.2-26.11-37.36-50.34-42.69q-.88,5.82-1.79,11.63c22.61,2.71,40.5,19.12,44,39.15C556.88,311.22,538.81,337.73,510.28,343Z" />
                        <path className="cls-3" d="M480.78,350.83a275.11,275.11,0,0,1-238.53-3.57l13.25-9.46a241.42,241.42,0,0,0,216,1.63Z" />
                    </g>
                </g>
            </svg>
        )
}

export default LaptopSVG