import React from 'react'

const InstagramSVG = () => {
    return (
        <svg viewBox="0 0 30 30" className="SVG-instagram">
            <g id="Layer_2" data-name="Layer 2">
                <g id="_5-footer" data-name="5-footer">
                    <path className="cls-1" d="M21.88,0H8.12A8.13,8.13,0,0,0,0,8.12V21.88A8.13,8.13,0,0,0,8.12,30H21.88A8.13,8.13,0,0,0,30,21.88V8.12A8.13,8.13,0,0,0,21.88,0Zm6.36,21.88a6.36,6.36,0,0,1-6.36,6.36H8.12a6.36,6.36,0,0,1-6.36-6.36V8.12A6.36,6.36,0,0,1,8.12,1.76H21.88a6.36,6.36,0,0,1,6.36,6.36Z" />
                    <path className="cls-1" d="M15,6.8A8.2,8.2,0,1,0,23.2,15,8.21,8.21,0,0,0,15,6.8Zm0,14.64A6.44,6.44,0,1,1,21.44,15,6.45,6.45,0,0,1,15,21.44Z" />
                    <path className="cls-1" d="M23.4,3.88a2.43,2.43,0,1,0,2.42,2.43A2.42,2.42,0,0,0,23.4,3.88ZM23.4,7a.67.67,0,1,1,.66-.66A.66.66,0,0,1,23.4,7Z" />
                </g>
            </g>
        </svg>
    )
}

export default InstagramSVG