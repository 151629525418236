import React from 'react';

const TermsSVG = () => {
    return (
        <svg viewBox="0 0 638.37 481.02" className="terms-SVG">
            <g>
                <g id="Terms">
                    <rect className="cls-1" x="181.91" y="319.11" width="235.56" height="96.85" rx="9.47" />
                    <circle className="cls-2" cx="347" cy="299.89" r="128.72" />
                    <path className="cls-3" d="M207.66,276.61c2.45-6.58,4.85-13.54,7.14-20.88,2.8-9,5.13-17.61,7.09-25.81a27,27,0,0,0-14.23,46.69Z" />
                    <path className="cls-4" d="M146,481h56L202,377.35a47.3,47.3,0,0,0-18.64,2.72c-18.89,6.88-28.58,24.83-33.29,38a68.92,68.92,0,0,0-4,23.28Z" />
                    <path className="cls-3" d="M184,333l-.66,11.79c-27.68-16.68-37.09-48.93-25.62-71.06,11.88-22.9,41.24-25.49,43.1-25.63l-1.17,10.49c-2.33.08-22.87,1.07-32.61,16.31C156.68,291,162.23,317.47,184,333Z" />
                    <path className="cls-5" d="M243.48,308c0-74.38,75.94-126.7,132.82-128.71a99.59,99.59,0,0,1,19,1.16,107.39,107.39,0,0,0-43.08-9.32c-58.72.07-132.81,52.26-132.81,128.72,0,60.51,43.08,111.26,101.14,125C275.07,404.5,243.48,359.85,243.48,308Z" />
                    <path className="cls-6" d="M452.16,224.91c-49-66.54-138.2-79.39-193.49-38.69-42.83,31.53-61.42,92.36-44.23,150.36,1.66-16.5,9.7-76.13,59.71-118.3,14.49-12.22,46.42-39.14,92.87-36.49C415.41,184.56,445.64,217.48,452.16,224.91Z" />
                    <path className="cls-6" d="M202,481,202,377.35c3.27,6.16,43.53,78.94,127,87.37,63.25,6.39,108.31-28.53,117.65-36.11V481" />
                    <path className="cls-6" d="M298.66,433.27c-2.14-.72-4.25-1.49-4.25-1.49l-2.23-.84c-19.37-7.39-68-30-84.38-82.71-8.15-26.14-3.51-51.27.12-72.3a212.39,212.39,0,0,1,5.7-24.38c3.74,78.4,44.92,143,102.51,161.91,8.53,2.8,59.43,19.48,104.83-9.32,22.1-14,33.93-33.14,39.61-44.26a59.88,59.88,0,0,1,4.66,9.26,44,44,0,0,1,2.25,7.74c1.67,9.12-4.05,18.62-5.75,21.44-11.77,19.57-36.11,31.45-36.11,31.45S369.74,457.06,298.66,433.27Z" />
                    <path className="cls-4" d="M446.59,421.5h0A38.44,38.44,0,0,1,485,459.94V481a0,0,0,0,1,0,0H446.59a0,0,0,0,1,0,0V421.5A0,0,0,0,1,446.59,421.5Z" />
                    <path className="cls-1" d="M461.73,414.63c-7.26,7.65-52,53.22-121.14,51.25C283,464.24,230.09,430.17,202,377.35l1.17-39.6c22.83,59.06,76.19,99.67,136.28,103.67,73.22,4.88,121-46.93,127-53.58a58.15,58.15,0,0,1,0,7A60.1,60.1,0,0,1,461.73,414.63Z" />
                    <path className="cls-7" d="M376.05,29.81c0-.44.05-.88.05-1.33a17.21,17.21,0,0,0-27.66-13.67A22.84,22.84,0,0,0,308,10.26a17.71,17.71,0,0,0-21.77,22.23h-.42a15.54,15.54,0,0,0-15.65,15.8,15.56,15.56,0,0,0,17.26,14.64l17.26-.18h68.54A15.69,15.69,0,0,0,388.9,47.06V45.24A15.7,15.7,0,0,0,376.05,29.81Z" />
                    <path className="cls-8" d="M624,120.05c0-.49.06-1,.06-1.49a19.27,19.27,0,0,0-31-15.32,25.59,25.59,0,0,0-45.31-5.09,19.84,19.84,0,0,0-24.38,24.9h-.48a17.12,17.12,0,1,0,1.81,34.09l19.33-.2H620.8a17.57,17.57,0,0,0,17.57-17.57v-2.05A17.57,17.57,0,0,0,624,120.05Z" />
                    <path className="cls-8" d="M151.28,116.4c0-.43.06-.87.06-1.31A17,17,0,0,0,124,101.57a22.59,22.59,0,0,0-42-.79,22.6,22.6,0,0,0-42,.79,17,17,0,0,0-27.35,13.52c0,.44,0,.88.06,1.31A15.53,15.53,0,0,0,0,131.66v1.81A15.51,15.51,0,0,0,15.51,149h133A15.5,15.5,0,0,0,164,133.47v-1.81A15.53,15.53,0,0,0,151.28,116.4Z" />
                </g>
            </g>
        </svg>
    )
}

export default TermsSVG