import React from 'react'
import LaptopSVG from './svgs/laptopSVG'
import Navbar from './navbar'

const Header = ({ refHandler }) => {
    return (
        <div className="header-body text-right">
            {/* navbar */}
            <Navbar refHandler={refHandler}/>

            {/* SVG and description */}
            <div className="row m-0">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 description-text">
                    <h1> سرویس ابری آرویکس </h1>
                    <p>
                        سرویس های ابری آرویکس برگرفته از بهترین و قدرتمند ترین سخت افزار های روز هستند که در دیتاسنتر هتزنر آلمان میزبانی می شوند. در واقع این سرویس ها ارائه شده توسط خود دیتاسنتر و مناسب برای افرادی هستند که تمایلی به تهیه سرور اختصاصی ندارند و یا نیاز به منابع متغیر در مقاطع زمانی مختلف دارند. شما به راحتی و از طریق پنلی پیشرفته قادر به مدیریت سرور های خود خواهید بود.
                    </p>
                    <div className="w-100 text-right mt-3">
                        <button onClick={() => refHandler("whyArvix")}>بیشتر بدانید</button>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 laptop-user-SVG">
                    <LaptopSVG />
                </div>
            </div>

            {/* footer SVG */}
            <div className="">
                {/* <CloudSVG /> */}
                <img className="footer-SVG-header" src="./static/img/cloud-under.png" width="100%" height="240px" alt="cover" />
            </div>

        </div>
    )
}

export default Header