import React from 'react';

const MobileSVG = () => {
    return (
        <svg viewBox="0 0 555.35 507.94" className="SVG-mobile">
            <g id="Layer_2" data-name="Layer 2">
                <g id="_2-intro" data-name="2-intro">
                    <path className="cls-1" d="M231.4,11.73A488.67,488.67,0,0,0,109.05,121.19C95.49,138.32-50.67,326.91,18.48,436.73c46.29,73.51,164.11,72.12,268.93,70.89,101.63-1.2,215.11-2.53,254.28-70.89,58-101.23-85.17-282.7-122.81-330.4a704.42,704.42,0,0,0-90.11-94.6,106.89,106.89,0,0,0-97.37,0Z" />
                    <path className="cls-2" d="M427,399c5.29,8.87,5.16,17.26,5,28-.16,10.41-.24,21.05-7,32a49.75,49.75,0,0,1-16,16H322q-1.63-37.38-3.26-74.75L363,405l10-1a69.45,69.45,0,0,1,19-14c7.69-3.83,13.4-6.74,20-5C420.38,387.21,424.39,394.63,427,399Z" />
                    <path className="cls-3" d="M391,212.14q.06,6.71.14,13.41c31-10.27,46.93-40.56,39.8-64.77-4.95-16.83-20.77-29.66-40-33.88-.47,3.08-.94,6.17-1.42,9.25,18,2.13,32.2,15.16,35,31.07C428,186.83,413.61,207.91,391,212.14Z" />
                    <rect className="cls-4" x="159.58" y="183.32" width="235.62" height="78.68" rx="12.5" />
                    <path className="cls-2" d="M437,346.87A161.38,161.38,0,0,1,381,352c3.9-11.77,7.12-28.36,1-45-2.84-7.73-6.35-11.8-13-22a324.42,324.42,0,0,1-23.51-42.95l13.2-31.87a238.89,238.89,0,0,1,29.59,31C419.11,279.82,431.54,320.25,437,346.87Z" />
                    <rect className="cls-5" x="370.39" y="283" width="46.8" height="8.67" rx="2.75" transform="translate(-63.58 118.67) rotate(-15.87)" />
                    <rect className="cls-5" x="366.39" y="270" width="46.8" height="8.67" rx="2.75" transform="translate(-60.17 117.08) rotate(-15.87)" />
                    <path className="cls-6" d="M363.46,404.8A673.27,673.27,0,0,1,281,410,672.29,672.29,0,0,1,190,404c-2.87-20.64-5-43-6-66.95a683.45,683.45,0,0,1,7.05-128.46l164.58.54c4.5,39.56,7.58,82.32,8.37,127.92C364.41,360.41,364.18,383,363.46,404.8Z" />
                    <path className="cls-3" d="M380.57,153.48q-.93-8.37-1.45-17.59c-.41-7.52-.53-14.66-.44-21.4a21.59,21.59,0,0,1,1.89,39Z" />
                    <path className="cls-3" d="M170.87,153.67c.6-5.58,1.09-11.45,1.41-17.59.4-7.51.5-14.66.4-21.39a21.58,21.58,0,0,0-1.81,39Z" />
                    <path className="cls-6" d="M378.65,113.64c15.91,52.15-19,95.29-21.81,98.63l-167.43-2.8c-23.15-32-27-74-10-109,18.92-38.93,58.88-59.38,96.9-59.29C319.73,41.25,364.78,68.14,378.65,113.64Z" />
                    <path className="cls-5" d="M359.9,140.38c-3,40.58-41.62,70.09-79.88,72.3-42,2.41-87-27.91-90.34-72.13-3.49-45.83,39.3-85.15,85-85.2C320.73,55.31,363.27,95,359.9,140.38Z" />
                    <path className="cls-2" d="M113.63,346.87a161.45,161.45,0,0,0,56,5.13c-3.9-11.77-7.12-28.36-1-45,3.52-9.55,8.16-15.42,13-22,8.76-11.91,20.17-30,30.37-56l-20.06-18.82a239.56,239.56,0,0,0-29.59,31C131.53,279.82,119.1,320.25,113.63,346.87Z" />
                    <path className="cls-7" d="M323.56,70.81c24.06,16,38.3,42.57,36.34,69.57-2.56,35.42-32.62,66.26-72.5,71,32-9.14,55.83-35.3,61.05-67C355.32,102.8,326.32,73.51,323.56,70.81Z" />
                    <path className="cls-4" d="M367.51,218.36a219.28,219.28,0,0,1-90.41,20.31,219.84,219.84,0,0,1-99.13-23l10.52-7.54a191.77,191.77,0,0,0,171.67,1.14Z" />
                    <path className="cls-2" d="M299,361H261.14a8.31,8.31,0,0,1-8.31-8.14l-1.65-78.37a8.31,8.31,0,0,1,8.31-8.49h41.93a8.31,8.31,0,0,1,8.31,8.58q-1.25,39.18-2.48,78.37A8.31,8.31,0,0,1,299,361Z" />
                    <path className="cls-2" d="M347,399q-1,38.17-2,76.34l-126.55.12a33.91,33.91,0,0,1-33.94-33.87q0-21.25,0-42.51L316.76,399A83.21,83.21,0,0,1,347,385Z" />
                    <circle className="cls-6" cx="281" cy="295" r="8" />
                    <ellipse className="cls-4" cx="408.5" cy="430" rx="25.5" ry="45" />
                    <ellipse className="cls-4" cx="349.5" cy="430" rx="25.5" ry="45" />
                    <path className="cls-2" d="M349,354a261.87,261.87,0,0,0,29,1,263.52,263.52,0,0,0,59-8l-52-37H348Q348.5,332,349,354Z" />
                    <path className="cls-3" d="M317,305.82h11.21a16.47,16.47,0,0,1,16.47,16.47v35.4a0,0,0,0,1,0,0H300.5a0,0,0,0,1,0,0v-35.4A16.47,16.47,0,0,1,317,305.82Z" transform="translate(-8.87 654.64) rotate(-90.05)" />
                    <rect className="cls-5" x="133.45" y="283" width="46.8" height="8.67" rx="2.75" transform="matrix(-0.96, -0.27, 0.27, -0.96, 229.12, 606.61)" />
                    <rect className="cls-5" x="137.45" y="270" width="46.8" height="8.67" rx="2.75" transform="translate(240.53 582.2) rotate(-164.13)" />
                    <path className="cls-2" d="M201.63,354a261.87,261.87,0,0,1-29,1,263.6,263.6,0,0,1-59-8l52-37h37Q202.14,332,201.63,354Z" />
                    <path className="cls-3" d="M206,305.82h44.14a0,0,0,0,1,0,0v35.4a16.47,16.47,0,0,1-16.47,16.47H222.46A16.47,16.47,0,0,1,206,341.22v-35.4A0,0,0,0,1,206,305.82Z" transform="translate(-103.91 559.5) rotate(-89.95)" />
                    <path className="cls-3" d="M389.41,312.25c.1-.34,13.75.55,26.43,9.5,12.31,8.68,17.7,20.73,17.42,20.95s-5.83-10.92-18.54-19.8C401.85,313.9,389.32,312.57,389.41,312.25Z" />
                    <path className="cls-3" d="M114.28,345.9c-.26-.23,6.31-12.22,19.79-19.93,13.08-7.48,26.28-7.27,26.35-6.92s-12.36.67-25.78,8.42C121,335.33,114.54,346.12,114.28,345.9Z" />
                    <rect className="cls-5" x="326.39" y="328" width="46.8" height="8.67" rx="2.75" transform="translate(17.79 682.43) rotate(-90.05)" />
                    <rect className="cls-5" x="177.39" y="328" width="46.8" height="8.67" rx="2.75" transform="translate(-131.35 533.43) rotate(-90.05)" />
                </g>
            </g>
        </svg>
    )
}

export default MobileSVG