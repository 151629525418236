import React, { createRef, useLayoutEffect, useMemo } from 'react';
import Footer from '../components/footer'
import Header from '../components/header';
import WhyArvix from '../components/whyArvix';
import ServiceFeatures from '../components/serviceFeatures';
import PriceList from '../components/priceList';


const scrollToRef = (ref) => ref.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
})

const Landing = (props) => {
    const whyArvixRef = createRef()
    const featuresRef = createRef()
    const priceListRef = createRef()

    const refList = useMemo(() => {
		return {
			"whyArvix": whyArvixRef,
			"features": featuresRef,
			"priceList": priceListRef,
		}
	}, [whyArvixRef, featuresRef, priceListRef])

	useMemo(() => {
		return {
			"whyArvix": whyArvixRef,
			"features": featuresRef,
			"priceList": priceListRef,
		}
	}, [whyArvixRef, featuresRef, priceListRef])

    const refHandler = (key) => {
        scrollToRef(refList[key])
    }

    useLayoutEffect(() => {
        if (props.location.hash) {
            // scrollToRef doesn'
            const hash = props.location.hash.replace("#", "")
            const element = document.getElementById(hash);
            // scrollToRef doesn't work on page reload
            setTimeout(() => {
                window.scrollTo({
                    behavior: element ? "smooth" : "auto",
                    top: element ? element.offsetTop : 0
                });
            }, 100);
        }
    }, [props, refList])

    return (
        <div className="landing-page">
            {/* header */}
            <Header refHandler={refHandler} />

            <div className="support-svg-header"></div>

            {/* content */}
            <div className="first-description">
                {/* Why Arvix */}
                <div ref={whyArvixRef} id="whyArvix" />
                <WhyArvix />

                {/* Service Features */}
                <div ref={featuresRef} id="features" />
                <ServiceFeatures />

                {/* Price List */}
                <div ref={priceListRef} id="priceList" />
                <PriceList />
            </div>

            {/* footer */}
            <Footer />
        </div>
    )
}

export default Landing