import React from "react";
const ServerPriceTable = ({
  name,
  monthlyPrice,
  hourlyPrice,
  cpu,
  ram,
  diskSpace,
  traffic,
}) => {
  return (
    <div className="card-theme" key={name}>
      <div className="card-body text-center p-0 pb-3">
        <h4 className="pt-2 pb-3 cards-title">{name}</h4>
        <div className="mb-3">
          <span className="pricing-table-price pr-1 pl-1">
            {monthlyPrice} تومان
          </span>
          <p> ماهانه </p>
        </div>
        <div className="d-flex justify-content-center">
          <h6 className="pr-1 pl-1 mb-0 pricing-table-price">{hourlyPrice}</h6>
          <p style={{ marginTop: "0.3rem" }}> تومان به ازای هر ساعت </p>
        </div>
        <ul className="pricing-table-list list-unstyled mb-3">
          <li>
            <strong>{cpu}</strong> vCPU
          </li>
          <li>
            <strong>{ram}</strong> RAM{" "}
          </li>
          <li>
            <strong>{diskSpace}</strong> Disk space{" "}
          </li>
          <li>
            <strong>{traffic}</strong> Traffic{" "}
          </li>
          <li>
            <img src="/static/img/de-icon.png" alt="Germany Flag" />
            &nbsp;
            <img src="/static/img/fi-icon.png" alt="Germany Flag" />
            &nbsp;
            <img src="/static/img/us-icon.jpg" alt="United States Flag" />
            &nbsp; :Location
          </li>
        </ul>
        <a href="https://panel.arvix.cloud" className="btn-card-dark">
          {" "}
          خرید{" "}
        </a>
      </div>
    </div>
  );
};

export default ServerPriceTable;
