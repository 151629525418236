import React from 'react'

const TelegramSVG = () => {
    return (
        <svg viewBox="0 0 30 22.92" className="SVG-telegram">
            <g id="Layer_2" data-name="Layer 2">
                <g id="_5-footer" data-name="5-footer">
                    <path className="cls-1" d="M29.75.4A1.07,1.07,0,0,0,28.54.07L1,10a1.55,1.55,0,0,0-.35,2.73l3.94,2.68a1.23,1.23,0,0,0,1.28.07L14.42,11,7.77,16a1.26,1.26,0,0,0-.5,1v4.6a1.29,1.29,0,0,0,2,1.07l3-2,2.94,2a1.54,1.54,0,0,0,.87.27,1.73,1.73,0,0,0,.32,0,1.57,1.57,0,0,0,1-.7L29.84,1.66A1.08,1.08,0,0,0,29.75.4ZM16,21.07,13,19a1.23,1.23,0,0,0-1.39,0L9,20.68V17.22L20.77,8.45a1.24,1.24,0,0,0-1.32-2.1L5.34,13.79,2,11.52,27.36,2.36Z" />
                </g>
            </g>
        </svg>
    )
}

export default TelegramSVG