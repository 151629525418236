import React from 'react'

const volumePriceTable = ({ size, price }) => {
    return (
        <div className="card-theme" key={size + price}>
            <div className="card-body text-center p-0 pb-3">
                <h4 className="pt-2 cards-title">{size} گیگابایت</h4>
                <div className="mb-3">
                    <span className="pricing-table-price pr-1 pl-1">{price} تومان</span>
                    <p> ماهانه </p>
                </div>
                {/* <div className="d-flex justify-content-center">
                    <h6 className="pr-1 pl-1 mb-0 pricing-table-price"> 0.29 <TomanSVG /> </h6>
                    <p style={{ marginTop: "0.3rem" }}> به ازای هر ساعت </p>
                </div> */}
            </div>
        </div>
    )
}

export default volumePriceTable