import React from 'react'
import TermsSVG from './svgs/termsSVG'
import Navbar from './navbar'

const TermsHeader = ({ refHandler }) => {
    return (
        <div className="header-body terms-header text-right">
            {/* navbar */}
            <Navbar refHandler={refHandler}/>
            {/* SVG and description */}
            <div className="row m-0">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 description-text">
                    <h1> شرایط استفاده از سرویس ها </h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 laptop-user-SVG">
                    <TermsSVG />
                </div>
            </div>
        </div>
    )
}

export default TermsHeader