import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Landing from "./routes/landing";
import TermsPage from "./routes/terms";

const App = () => {
  return (
    <BrowserRouter>
      <div className="">
        <Switch>
          <Route
            exact
            path={"/tos"}
            render={(props) => <TermsPage {...props} />}
          />
          <Route path={"/"} render={(props) => <Landing {...props} />} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
