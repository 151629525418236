import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import LogoSVG from "./svgs/logoSVG";

const Navbar = ({ refHandler }) => {
  const node = useRef();

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setNavbarOpen(false);
  };

  useEffect(() => {
    if (navbarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navbarOpen]);

  return (
    <div className="d-flex" ref={node}>
      <nav className="navbar">
        <label
          className="mobile-menu"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <svg viewBox="0 0 482.239 482.239">
            <path
              id="path-1_8_"
              d="m0 0h482.239v34.446h-482.239z"
              transform="translate(1 1)"
            />
            <path
              id="path-1_7_"
              d="m0 223.897h482.239v34.446h-482.239z"
              transform="translate(1 7.5)"
            />
            <path
              id="path-1_6_"
              d="m0 447.793h482.239v34.446h-482.239z"
              transform="translate(1 14)"
            />
          </svg>
        </label>

        <a href="/" className="head-logo" data-toggle="tab">
          {" "}
          <LogoSVG />{" "}
        </a>

        <ul className={navbarOpen ? "nav-tabs nav-down" : "nav-tabs"}>
          <li className="nav-item" onClick={() => refHandler("whyArvix")}>
            {" "}
            <span> بیشتر بدانید </span>{" "}
          </li>
          <li className="" onClick={() => refHandler("features")}>
            {" "}
            <span> ویژگی ها </span>{" "}
          </li>
          <li className="" onClick={() => refHandler("priceList")}>
            {" "}
            <span> قیمت ها </span>{" "}
          </li>
          <Link to="/tos">
            <li className="">
              {" "}
              <span> قوانین و مقررات </span>{" "}
            </li>
          </Link>
          <a href="https://panel.arvix.cloud">
            <li className="">
              {" "}
              <span> ورود و عضویت </span>{" "}
            </li>
          </a>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
