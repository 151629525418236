import React from 'react';

const AgreementSVG = () => {
    return (
        <svg className="agreement-SVG" viewBox="0 0 148.96 166.35">
            <g>
                <g id="Terms">
                    <path className="cls-1" d="M4.13,1.5H56.69A17.79,17.79,0,0,1,74.48,19.29v88.66a2.63,2.63,0,0,1-2.63,2.63H4.13a2.63,2.63,0,0,1-2.63-2.63V4.13A2.63,2.63,0,0,1,4.13,1.5Z" />
                    <path className="cls-1" d="M2.65,89.69H59a15.45,15.45,0,0,1,15.45,15.45v4.29a1.15,1.15,0,0,1-1.15,1.15H2.65a1.15,1.15,0,0,1-1.15-1.15V90.84A1.15,1.15,0,0,1,2.65,89.69Z" />
                    <path className="cls-1" d="M77.11,1.5h67.72a2.63,2.63,0,0,1,2.63,2.63V92.79a17.79,17.79,0,0,1-17.79,17.79H77.11a2.63,2.63,0,0,1-2.63-2.63V4.13A2.63,2.63,0,0,1,77.11,1.5Z" transform="translate(221.95 112.08) rotate(-180)" />
                    <path className="cls-1" d="M75.94,89.69h70.37a1.15,1.15,0,0,1,1.15,1.15v4.32A15.42,15.42,0,0,1,132,110.58H75.94a1.15,1.15,0,0,1-1.15-1.15V90.84A1.15,1.15,0,0,1,75.94,89.69Z" transform="translate(222.26 200.27) rotate(-180)" />
                    <rect className="cls-1" x="23.71" y="136.3" width="28.55" height="28.55" rx="3.24" />
                    <rect className="cls-1" x="96.7" y="136.3" width="28.55" height="28.55" rx="3.24" />
                    <line className="cls-2" x1="21.01" y1="24.97" x2="52.27" y2="24.97" />
                    <line className="cls-2" x1="21.01" y1="37.77" x2="52.27" y2="37.77" />
                    <line className="cls-2" x1="21.01" y1="50.56" x2="52.27" y2="50.56" />
                    <line className="cls-2" x1="21.01" y1="63.29" x2="52.27" y2="63.29" />
                    <line className="cls-2" x1="96.7" y1="24.97" x2="127.96" y2="24.97" />
                    <line className="cls-2" x1="96.7" y1="37.77" x2="127.96" y2="37.77" />
                    <line className="cls-2" x1="96.7" y1="50.56" x2="127.96" y2="50.56" />
                    <line className="cls-2" x1="96.7" y1="63.29" x2="127.96" y2="63.29" />
                    <path className="cls-3" d="M30.34,152.21c1.72,1.88,3.45,3.75,5.18,5.63l12.82-12.63" />
                    <line className="cls-4" x1="103.11" y1="142.72" x2="118.83" y2="158.44" />
                    <line className="cls-4" x1="118.92" y1="142.63" x2="103.2" y2="158.35" />
                </g>
            </g>
        </svg>
    )
}

export default AgreementSVG