import React, { useState } from 'react'
import ServerPriceTable from './serverPriceTable'
import VolumePriceTable from './volumePriceTable'
import IPPriceTable from './ipPriceTable'

const PriceList = () => {
    const [activeVPSTab, setActiveVPSTab] = useState("shared")
    const [activeServiceTab, setActiveServiceTab] = useState("volumes")
    return (
        <div>
            <div className="row mt-5 mb-3">
                <div className="w-100 text-center">
                    <h3 className="header-title-row">قیمت سرویس ها</h3>
                </div>

                <div className="card-body">
                    <ul className="tabs-landing">
                        <li className={activeVPSTab === "shared" ? "active" : ""} onClick={() => setActiveVPSTab("shared")}><span>اشتراکی</span></li>
                        <li className={activeVPSTab === "dedicated" ? "active" : ""} onClick={() => setActiveVPSTab("dedicated")}><span>اختصاصی</span></li>
                    </ul>

                    <div className="tab-content">

                        <div id="shared" className={activeVPSTab === "shared" ? "tab-pane active show" : "tab-pane"} key="shared">
                            <div className="w-100 text-right text-grey mb-4">
                                <p><strong>سی پی یو های اشتراکی:</strong> مناسب برای استفاده های سبک، تست، پروژه های شخصی و سرویس های کم ترافیک با بهترین قیمت ممکن.</p>
                            </div>

                            <div className="row">
                                <ServerPriceTable
                                    name={"CX11"}
                                    monthlyPrice={"۲۹۰۰۰۰"}
                                    hourlyPrice={"۴۰۵"}
                                    cpu={"1Core"}
                                    ram={"2GB"}
                                    diskSpace={"20GB"}
                                    traffic={"20TB"}
                                />
								<ServerPriceTable
                                    name={"CPX11"}
                                    monthlyPrice={"۳۴۵۰۰۰"}
                                    hourlyPrice={"۴۸۰"}
                                    cpu={"2Core"}
                                    ram={"2GB"}
                                    diskSpace={"40GB"}
                                    traffic={"20TB"}
                                />
                                <ServerPriceTable
                                    name={"CX21"}
                                    monthlyPrice={"۴۲۰۰۰۰"}
                                    hourlyPrice={"۵۸۸"}
                                    cpu={"2Core"}
                                    ram={"4GB"}
                                    diskSpace={"40GB"}
                                    traffic={"20TB"}
                                />
								<ServerPriceTable
                                    name={"CPX21"}
                                    monthlyPrice={"۵۸۰۰۰۰"}
                                    hourlyPrice={"۸۱۰"}
                                    cpu={"3Core"}
                                    ram={"4GB"}
                                    diskSpace={"80GB"}
                                    traffic={"20TB"}
                                />
                                <ServerPriceTable
                                    name={"CX31"}
                                    monthlyPrice={"۷۴۰۰۰۰"}
                                    hourlyPrice={"۱۰۳۳"}
                                    cpu={"2Core"}
                                    ram={"8GB"}
                                    diskSpace={"80GB"}
                                    traffic={"20TB"}
                                />
								<ServerPriceTable
                                    name={"CPX31"}
                                    monthlyPrice={"۵۳۲۰۰۰"}
                                    hourlyPrice={"۷۴۰"}
                                    cpu={"4Core"}
                                    ram={"8GB"}
                                    diskSpace={"160GB"}
                                    traffic={"20TB"}
                                />
                                <ServerPriceTable
                                    name={"CX41"}
                                    monthlyPrice={"۱۰۶۰۰۰۰"}
                                    hourlyPrice={"۱۹۳۱"}
                                    cpu={"4Core"}
                                    ram={"16GB"}
                                    diskSpace={"160GB"}
                                    traffic={"20TB"}
                                />
								<ServerPriceTable
                                    name={"CPX41"}
                                    monthlyPrice={"۲۰۲۰۰۰۰"}
                                    hourlyPrice={"۲۸۱۵"}
                                    cpu={"8Core"}
                                    ram={"16GB"}
                                    diskSpace={"240GB"}
                                    traffic={"20TB"}
                                />
                                <ServerPriceTable
                                    name={"CX51"}
                                    monthlyPrice={"۲۶۶۰۰۰۰"}
                                    hourlyPrice={"۳۷۰۶"}
                                    cpu={"8Core"}
                                    ram={"32GB"}
                                    diskSpace={"240GB"}
                                    traffic={"20TB"}
                                />
								<ServerPriceTable
                                    name={"CPX51"}
                                    monthlyPrice={"۴۲۷۰۰۰۰"}
                                    hourlyPrice={"۵۹۴۰"}
                                    cpu={"16Core"}
                                    ram={"32GB"}
                                    diskSpace={"360GB"}
                                    traffic={"20TB"}
                                />
                            </div>
                        </div>
                        <div id="dedicated" className={activeVPSTab === "dedicated" ? "tab-pane active show" : "tab-pane"} key="dedicated">
                            <div className="w-100 text-right text-grey mb-4">
                                <p><strong>سی پی یو های اختصاصی:</strong> حداکثر توان سی پی یو به صورت اختصاصی در اختیار شما. مناسب برای پردازش های سنگین، انکود ویدئو و وبسایت های پر بازدید.</p>
                            </div>

                            <div className="row">
                                <ServerPriceTable
                                    name={"CCX11"}
                                    monthlyPrice={"۱۶۰۰۰۰۰"}
                                    hourlyPrice={"۲۲۲۸"}
                                    cpu={"2Core"}
                                    ram={"8GB"}
                                    diskSpace={"80GB"}
                                    traffic={"20TB"}
                                />
                                <ServerPriceTable
                                    name={"CCX21"}
                                    monthlyPrice={"۳۲۰۰۰۰۰"}
                                    hourlyPrice={"۴۴۵۵"}
                                    cpu={"4Core"}
                                    ram={"16GB"}
                                    diskSpace={"160GB"}
                                    traffic={"20TB"}
                                />
                                <ServerPriceTable
                                    name={"CCX31"}
                                    monthlyPrice={"۶۴۰۰۰۰۰"}
                                    hourlyPrice={"۸۹۱۰"}
                                    cpu={"8Core"}
                                    ram={"32GB"}
                                    diskSpace={"240GB"}
                                    traffic={"20TB"}
                                />
                                <ServerPriceTable
                                    name={"CCX41"}
                                    monthlyPrice={"۱۲۸۰۰۰۰۰"}
                                    hourlyPrice={"۱۷۸۴۰"}
                                    cpu={"16Core"}
                                    ram={"64GB"}
                                    diskSpace={"360GB"}
                                    traffic={"20TB"}
                                />
                                <ServerPriceTable
                                    name={"CCX51"}
                                    monthlyPrice={"۲۵۶۰۰۰۰۰"}
                                    hourlyPrice={"۳۵۶۰۰"}
                                    cpu={"32Core"}
                                    ram={"128GB"}
                                    diskSpace={"600GB"}
                                    traffic={"20TB"}
                                />
								<ServerPriceTable
                                    name={"CCX62"}
                                    monthlyPrice={"۳۸۴۰۰۰۰۰"}
                                    hourlyPrice={"۵۳۴۰۰"}
                                    cpu={"48Core"}
                                    ram={"192GB"}
                                    diskSpace={"960GB"}
                                    traffic={"20TB"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* tariff */}
            <div className="row mt-5 mb-3">
                <div className="card-body">
                    <ul className="tabs-landing">
                        <li className={activeServiceTab === "volumes" ? "active" : ""} onClick={() => { setActiveServiceTab("volumes") }}><span> فضای اضافه </span></li>
                        <li className={activeServiceTab === "ip" ? "active" : ""} onClick={() => { setActiveServiceTab("ip") }}><span> آی پی اضافه </span></li>
                        <li className={activeServiceTab === "snapShot" ? "active" : ""} onClick={() => { setActiveServiceTab("snapShot") }}><span> بک آپ و اسنپ شات </span></li>
                    </ul>

                    <div className="tab-content">

                        <div id="volumes" className={activeServiceTab === "volumes" ? "tab-pane active show" : "tab-pane"} key="volumes">
                            <div className="w-100 text-right text-grey mb-4">
                                <p>به طور کلی قیمت هر گیگابایت فضای اضافه ۲۶۸۰ تومان خواهد بود و امکان اضافه کردن حداکثر ۱۰ ترابایت فضا به هر سرور وجود خواهد داشت.</p>
                            </div>

                            <div className="row">
                                <VolumePriceTable size={"۱۰"} price={"۲۶۸۰۰"} />
                                <VolumePriceTable size={"۲۰"} price={"۵۳۶۰۰"} />
                                <VolumePriceTable size={"۳۰"} price={"۸۰۴۰۰"} />
                                <VolumePriceTable size={"۴۰"} price={"۱۰۷۲۰۰"} />
                                <VolumePriceTable size={"۵۰"} price={"۱۳۴۰۰۰"} />

                                <VolumePriceTable size={"۱۰۰"} price={"۲۶۸۰۰۰"} />
                                <VolumePriceTable size={"۲۰۰"} price={"۵۳۶۰۰۰"} />
                                <VolumePriceTable size={"۴۰۰"} price={"۱۰۷۲۰۰۰"} />
                                <VolumePriceTable size={"۸۰۰"} price={"۲۱۴۴۰۰۰"} />
                                <VolumePriceTable size={"۱۶۰۰"} price={"۴۲۸۸۰۰۰"} />
                            </div>
                            <div className="w-100 text-center mt-5">
                                <a href="https://panel.arvix.cloud" className="btn-card-dark"> خرید </a>
                            </div>
                        </div>

                        <div id="ip" className={activeServiceTab === "ip" ? "tab-pane active show" : "tab-pane"} key="ip">
                            <div className="w-100 text-right text-grey mb-4">
                                <p>هزینه هر آی پی ۱۱۱۰۰۰ تومان است و شما قادر خواهید بود آی پی های تهیه شده را به هر سروری متصل کنید.</p>
                            </div>

                            <div className="row">
                                <IPPriceTable count={"۱"} price={"۲۰۰۰۰۰"} />
                                <IPPriceTable count={"۲"} price={"۴۰۰۰۰۰"} />
                                <IPPriceTable count={"۳"} price={"۶۰۰۰۰۰"} />
                                <IPPriceTable count={"۴"} price={"۸۰۰۰۰۰"} />
                                <IPPriceTable count={"۵"} price={"۱۰۰۰۰۰۰"} />
                            </div>
                            <div className="w-100 text-center mt-5">
                                <a href="https://panel.arvix.cloud" className="btn-card-dark"> خرید </a>
                            </div>
                        </div>

                        <div id="snapShot" className={activeServiceTab === "snapShot" ? "tab-pane active show" : "tab-pane"} key="snapShot">
                            <div className="w-100 text-right text-grey mb-4">
                                <p>قیمت اسنپ شات بر اساس حجم دیتای سرور شما محاسبه خواهد شد. قیمت هر گیگابایت ۷۵۰ تومان خواهد بود.</p>
                                <p>قیمت بک آپ نیز ۲۰% از هزینه سرور شما خواهد بود و حداکثر ۷ بک آپ قابل ذخیره سازی هستند.</p>
                            </div>

                            <div className="tables-width">
                                <div className="card-theme">
                                    <div className="card-body text-center p-0">
                                        <div className="head-table">
                                            <h5 className="w-50 right-col"> سرویس </h5>
                                            <h5 className="w-50"> قیمت </h5>
                                        </div>

                                        <div className="item-table">
                                            <p className="w-50 mb-0 col-name-table"> اسنپ شات </p>
                                            <div className="justify-content-center w-50 mb-0">
                                                <h6 className="pr-1 pl-1 mb-0 pricing-table-price"> ۷۵۰ تومان </h6>
                                                <p className="mb-0" style={{ marginTop: "0.3rem" }}> به ازای هر گیگابایت </p>
                                            </div>
                                        </div>
                                        <div className="item-table">
                                            <p className="w-50 mb-0 col-name-table"> بک آپ </p>
                                            <div className="justify-content-center w-50 mb-0">
                                                <h6 className="pr-1 pl-1 mb-0 pricing-table-price"> ۲۰٪ </h6>
                                                <p className="mb-0" style={{ marginTop: "0.3rem" }}> هزینه سرور </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 text-center mt-5">
                                <a href="https://panel.arvix.cloud" className="btn-card-dark"> خرید </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default PriceList
